import React from 'react';
import {Script} from 'gatsby';

export function ReviewsSlider({currentStore}) {
  return (
    <section className='w-100 pt-4 milan-text-white milan-bg-primary' style={{minHeight: '300px'}}>
      <Script src={`https://reviewsonmywebsite.com/js/v2/embed.js?id=${currentStore.pathname}`} />
      <div className='container'>
        <h2 className='text-center subheadsm mb-0'>What Our {currentStore.marketingCity} Customers Are Saying</h2>
        <div data-romw-token={currentStore?.platforms?.reviews.slide_token}></div>
        <div data-romw-token={``}></div>
      </div>
    </section>
  );
}
export default ReviewsSlider;
