import React from "react";
import Card from "react-bootstrap/Card";

export function PressMilanMediaContCard(media) {
    return (
        <div className="col-md-3 d-flex justify-content-center item-height h-100">
        <Card>
          <Card.Body className="tile-box d-flex flex-column align-items-between">
            <Card.Title className="milan-text-navy">{media.media.title}</Card.Title>
            <Card.Subtitle className="text-muted">{media.media.publisher}</Card.Subtitle>
            <Card.Link href={media.media.source} target="_blank" className="mt-auto d-block milan-text-navy">
              Read more
            </Card.Link>
          </Card.Body>
        </Card>
      </div>
    )
}