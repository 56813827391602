import React from 'react'
import { Link } from 'gatsby'

import LARGEST_CO_BADGE from '../../images/shared/LargestUSCoBadge.webp'

export function LargestLHRBadge() {
  return (
    <section>
      <div className="container position-relative">
        <div className="row">
          <div className="col">
            <div id="largest-lhr-badge" className="mx-auto pb-5 pb-md-0" style={{ marginTop: '-20px' }} >
              <Link to="/locations/" className="d-block">
                <img
                  alt="Largest Laser Hair Removal Company In The U.S." className="w-100"
                  src={LARGEST_CO_BADGE} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LargestLHRBadge
