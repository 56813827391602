import React from 'react';
import {Link} from 'gatsby';
import {trimAll} from '../../utils';
import {FooterSocialIcons, SPECIALIZED, ABOUT_MILAN_ITEMS} from '.';
import {MilanLogo} from '../shared';

const FooterList = ({list}) => (
  <ul className='list-wrapper d-flex flex-column flex-wrap'>
    {list.map((item, i) => (
      <li key={i}>
        <Link to={`/${item.path}`}>{item.text}</Link>
      </li>
    ))}
  </ul>
);

const FooterListAbout = ({list}) => (
  <ul className='list-wrapper d-flex flex-column flex-wrap'>
    {list.map((item, i) => (
      <li key={i}>
        <Link to={`/${item.path}`}>{item.text}</Link>
      </li>
    ))}
    <li>
      <a href='javascript:void(0)' className='ot-sdk-show-settings'>
        Your Privacy Rights
      </a>
    </li>
    <li>
      <a href='https://privacyportal.onetrust.com/webform/7e74d7f0-eabc-4b52-b818-7fb62ee47b76/b98849a4-6e30-4e2b-b616-97fe3580aed8' target='_blank'>
        Consumer Privacy Request
      </a>
    </li>
    {/* <li><a href="https://privacyportal.onetrust.com/webform/7e74d7f0-eabc-4b52-b818-7fb62ee47b76/2a434d2c-c142-4e8a-bcb6-847ab1fe6b59" target="_blank">Milan Laser Employee Privacy Request</a></li> */}
  </ul>
);

export function FooterTop({milanStores}) {
  return (
    <section id='footer-top' className='w-100 pt-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-4 mb-4'>
            <h4 className='milan-text-secondary'>Milan Laser Hair Removal Locations</h4>
            <ol id='states-list' className='list-wrapper d-flex flex-column flex-wrap'>
              {milanStores.length !== 0 &&
                milanStores.map((state, s) => (
                  <li key={s}>
                    {/* <Link to={`/locations/${trimAll(state.name).toLowerCase()}/`}>{state.name}</Link> */}
                    <Link to={`/locations/${trimAll(state.abbreviation).toLowerCase()}/`}>{state.name}</Link>
                  </li>
                ))}
            </ol>
          </div>
          <div className='col-md-6 offset-lg-1 col-lg-4 mb-4'>
            <h4 className='milan-text-secondary'>Laser Hair Removal Topics</h4>
            <FooterList list={SPECIALIZED} />
          </div>
          <div className='col-md-6 col-lg-3 mb-4'>
            <h4 className='milan-text-secondary'>About Milan</h4>
            {/* <FooterList list={ABOUT_MILAN_ITEMS} /> */}
            <FooterListAbout list={ABOUT_MILAN_ITEMS} />
          </div>
          <div className='d-md-none pb-5'>
            <div className='row'>
              <div className='col-auto'>
                <Link to='/'>
                  <MilanLogo parentClasses='d-inline-block' type='white' />
                </Link>
              </div>
              <div className='col-auto'>
                <FooterSocialIcons />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default FooterTop;
