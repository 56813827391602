import React from 'react';
import {useThumbnailsMetro} from '../../../hooks';
import {MILAN_SITE_START} from '../../../utils';
import {StoreCard} from '../state';

export function OurMetroStores({currentMetro}) {
  const thumbnails = useThumbnailsMetro(currentMetro);

  return (
    <section id='our-metro-stores' className='pb-5 light-gray-bg'>
      <div className='container'>
        <div className='row justify-content-center'>
          {currentMetro.stores.map((store, x) => (
            <StoreCard
              key={x}
              store={store}
              thumbnails={thumbnails}
              link={
                currentMetro.isMacrosite
                  ? // REFACTOR: create function to return the correct pathname wd-321 wd-439
                    `/locations/${currentMetro.stateAbbrev.toLowerCase()}/${currentMetro.metroPath}/${store.pathname}/`
                  : `${currentMetro.origin}/locations/${currentMetro.stores.length === 1 ? '' : currentMetro.metroPath + '/'}${store.pathname}/`
              }
            />
          ))}
          <span id='borrowed-stores'></span>
        </div>
        {currentMetro.borrowed_stores && currentMetro.borrowed_stores.length !== 0 && (
          <>
            <h2 className='mt-5 pb-4 text-center milan-text-primary subheadsm'>Nearby Locations</h2>
            <div className='row justify-content-center'>
              {currentMetro.borrowed_stores.map((store, x) => (
                <StoreCard
                  key={x}
                  store={store.details}
                  thumbnails={thumbnails}
                  link={`${MILAN_SITE_START}${store.metro.pathname}.com/locations/${store.metro.metroPath}/${store.details.pathname}/`}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export default OurMetroStores;
