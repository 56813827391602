import React from 'react';
import {MILAN_PHONE} from '../../../utils';
// import { FaPhone } from 'react-icons/fa'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPhone} from '@fortawesome/free-solid-svg-icons';
import {StaticImage} from 'gatsby-plugin-image';

export function StoreHeroDetails({currentStore, currentMetro}) {
  return (
    <div className='shadow milan-bg-primary py-4 px-sm-4 text-center milan-text-white h-100'>
      <div className='my-4 d-none d-md-block'>
        <StaticImage src='../../../images/shared/Milan_Reverse.svg' alt='Milan Laser Hair Removal Logo' style={{maxWidth: '160px'}} />
      </div>
      {currentMetro?.stores?.length === 1 ? (
        <h1 className='pt-2 pt-sm-0 mb-2 smalltitle'>
          {currentMetro.name}, {currentMetro.stateAbbrev}
        </h1>
      ) : (
        <>
          <h1 className='pt-2 pt-sm-0 mb-2 pb-0 smalltitle'>{currentMetro?.name}</h1>
          <h2 className='subheadsm'>
            {/* {currentStore.marketingCity === currentMetro.name ? null : currentStore.marketingCity.includes(currentMetro.name) ? `${currentStore.marketingCity.slice(currentMetro.name.length + 1)}` : `(${(currentStore.marketingCity)})`} */}
            {/* This regex removes the matching string and an empty space */}
            {currentStore?.marketingCity === currentMetro?.name
              ? null
              : currentStore?.marketingCity?.includes(currentMetro?.name)
              ? currentStore?.marketingCity?.replace(new RegExp('\\b' + currentMetro.name + '\\b', 'gi'), '').trim()
              : `(${currentStore.marketingCity})`}
          </h2>
        </>
      )}
      {currentStore?.clinicName !== '' && <p className='p-subhead'>{currentStore?.clinicName}</p>}
      <p className='my-4'>
        {currentStore?.address}
        <br />
        {currentStore?.addressCity === '' ? currentStore?.marketingCity : currentStore?.addressCity}, {currentStore?.stateAbbrev} {currentStore?.zipCode}
      </p>
      <p className='mb-4'>
        {/* <FaPhone className="me-2" /> */}
        <FontAwesomeIcon icon={faPhone} flip='horizontal' className='me-2' />
        <a href={`tel:${currentStore?.phone === '' ? MILAN_PHONE : currentStore?.phone}`} className='milan-text-white'>
          {currentStore?.phone === '' ? MILAN_PHONE : currentStore?.phone}
        </a>
      </p>
      {currentStore?.open ? (
        <>
          <h5 className='mb-0'>Hours of Operation</h5>
          <p className='mb-2 mb-md-4'>
            Mon-Thu: 9 AM - 6 PM
            <br />
            Fri-Sat: 9 AM - 5 PM <small>(Subject to Change)</small>
          </p>
        </>
      ) : (
        <div className='p-sm-5'></div>
      )}
      <div className='pt-4'>
        <div className='mb-3'>
          <a href={currentStore?.maps?.google} className='milan-btn milan-navy-btn milan-mw-btn' target='_blank'>
            Get Directions In Google Maps
          </a>
        </div>
        <div className='mb-3'>
          <a href={currentStore?.maps?.apple} className='milan-btn milan-navy-btn milan-mw-btn' target='_blank'>
            Get Directions In Apple Maps
          </a>
        </div>
      </div>
    </div>
  );
}
export default StoreHeroDetails;
