import React from 'react'

export function AtHomeDiode() {
  return (
    <section id="at-home-diode" className="milan-py">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <h2 className="subheadlg milan-text-primary pb-2">At-home Diode vs.</h2>
            <h2 className="subheadsm milan-text-primary pb-3">Professional Laser Hair Removal</h2>
            <p>A diode laser uses one single wavelength of light, can be used on most skin tones, and uses <strong>true laser light</strong>, unlike IPL. Light is absorbed by the pigment in the hair follicle, similar to professional laser hair removal. <span className="d-none d-sm-inline">However, a</span><span className="d-inline d-sm-none">A</span><strong> diode can only treat a small area at a time</strong> <span className="d-none d-sm-inline">because of the wavelength,</span> so it’s <strong>time-consuming with patchy results.</strong> Removing hair from <span className="d-none d-sm-inline">a larger area such as</span> the legs or back could end up taking <strong>over 45 minutes per session</strong>—most <strong>treatments at Milan can be done over a lunch break.</strong></p>
            <ul className="list-style-disc">
              <li>No guarantees on results with diode lasers—<strong>less powerful</strong> than professional laser hair removal.</li>
              <li>Diodes are painful, have a <strong>greater potential for user error</strong>, and <strong>carry a high burn risk.</strong></li>
              <li><strong>Handheld devices are painful</strong> and lack comfort features such as the <strong>cooling mist used at all Milan Laser locations.</strong></li>
              <li>Breaks are needed to relieve pain, producing <strong>patchy, inconsistent results.</strong></li>
              <li>At most, IPL and diode users see about a 70% reduction in unwanted hair over <strong>3 months.</strong></li>
              <li><strong>No medical oversight</strong> with at-home diode and IPL devices.</li>
              <li><span className="d-none d-sm-inline">With IPL and diodes, there are hidden costs—replacement cartridges, attachments, the <strong>need for continued shaving and waxing.</strong></span><span className="d-inline d-sm-none">There are hidden costs— placement cartridges, attachment, and continued shaving and waxing.</span></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AtHomeDiode