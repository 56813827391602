import React from "react";
import Card from "react-bootstrap/Card";
import { DOWNLOAD_PATH } from "../../utils/constants";

export function PressMilanMediaCard(video) {
  return (
    <div className="col-md-4 d-flex justify-content-center item-height h-100 pb-5 pb-lg-0">
      <Card style={{ width: "26rem" }}>
        <div className="ratio ratio-16x9">
          <video controls className="embed-responsive-item pointer">
            <source src={DOWNLOAD_PATH + video.video.source} type="video/mp4"></source>
          </video>
        </div>
        <Card.Body>
          <h6 className="milan-text-white">{video.video.title}</h6>
          <Card.Text>
            <a href={video.video.link} target="_blank" rel="noreferrer" className="milan-text-white">
              {video.video.publisher}
            </a>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default PressMilanMediaCard
