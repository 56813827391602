import React from "react";

export function Sweepstakes() {
  return (
    <section id="sweepstakes" className="milan-bg-tur">
      <div className="container milan-py milan-text-white">
        <div className="text-center">
            <h2>Official Sweepstakes Rules</h2>
            <p className="mb-0">NO PURCHASE IS NECESSARY TO ENTER OR WIN.</p>
            <p>ONE ADDITIONAL ENTRY AWARDED FOR EACH PURCHASE MADE DURING THE CUSTOMER APPRECIATION SALE.</p>
        </div>
        <p className="milan-fp">1. Agreement to Rules: By participating, you agree to be fully unconditionally bound by these Rules, and you represent and warrant that you meet the eligibility requirements set forth herein. In addition, you agree to accept the decisions of Milan Laser Corporate, LLC doing business as Milan Laser Hair Removal (“Milan Laser”) as final and binding as it relates to the content.</p>
        <p className="milan-fp">2. The Sweepstakes are subject to all applicable federal, state, and local laws. Sweepstakes Period: Entries will be accepted in person starting on or about October 2, 2023, and ending October 31, 2023. All entries must be received by October 31, 2023, at 6:59 PM. All times are (GMT-06:00) Central Time (US & Canada). Void where prohibited.</p>
        <p className="milan-fp">3. How to Enter: The Sweepstakes must be entered by manually submitting Milan Laser’s electronic entry form or by being automatically entered into the drawing by purchasing a laser hair removal package between October 2, 2023, and October 31, 2023. For every body area purchased during that period, the purchaser will receive one (1) entry into the drawing with the exception that any purchase of or upgrade to a Full Body laser hair removal package will receive ten (10) entries into the drawing. The entry must fulfill all Sweepstakes requirements, as specified, to be eligible to win a prize. Entries that are not complete or do not adhere to the rules or specifications may be disqualified at the sole discretion of Milan Laser. You must fill in the information requested. You may not enter more times than indicated by using multiple email addresses, identities, or devices in an attempt to circumvent the rules. If you use fraudulent methods or otherwise attempt to circumvent the rules, your submission may be removed from eligibility at the sole discretion of Milan Laser.</p>
        <p className="milan-fp">4. Prizes: The approximate retail value of all prizes is $12,000. The 10 prizes are a refund on any purchases made between October 2, 2023, and October 31, 2023, or a $500 gift card if no purchase was made during that timeframe. You are not guaranteed to win a prize, and your chance of winning is dependent on the total number of eligible entries received. Actual/appraised value may differ at time of prize award. The specifics of the prize shall be solely determined by Milan Laser. No cash or other prize substitution permitted except at Milan Laser’s discretion. The prize is non-transferable. Any and all prize-related expenses, including without limitation any and all federal, state, and local taxes shall be the sole responsibility of the winner. No substitution of prize or transfer/assignment of prize to others or request for the cash equivalent by winners is permitted. Acceptance of prize constitutes permission for Milan Laser to use the winner's name, likeness, and entry for purposes of advertising and trade without further compensation unless prohibited by law. Redemption of prize requires a 30-day notice to the Sponsor for arrangements to be made. Prize must be redeemed within one year of the Sweepstakes date.</p>
        <p className="milan-fp">5. Odds: The odds of winning depend on the number of eligible entries received.</p>
        <p className="milan-fp">6. Winner selection and notification: Winner of the Sweepstakes will be selected in random Sweepstakes under the supervision of Milan Laser. Winner will be notified via social media and e-mail to the e-mail address provided on the electronic entry form or during the purchase of the laser hair removal package within five (5) days following the winner selection. Milan Laser shall have no liability for a winner's failure to receive notices due to winner's spam, junk e-mail, or other security settings or for winner's provision of incorrect or otherwise non-functioning contact information. If the selected winner cannot be contacted, is ineligible, fails to claim the prize within 15 days from the time award notification was sent, or fails to timely return a completed and executed declaration and releases as required, prize may be forfeited and an alternate winner selected. The receipt by winner of the prize offered in this Sweepstakes is conditioned upon compliance with any and all federal and state laws and regulations. ANY VIOLATION OF THESE OFFICIAL RULES BY ANY WINNER (AT MILAN LASER’S SOLE DISCRETION) WILL RESULT IN SUCH WINNER'S DISQUALIFICATION AS WINNER OF THE SWEEPSTAKES AND ALL PRIVILEGES AS WINNER WILL BE IMMEDIATELY TERMINATED.</p>
        <p className="milan-fp">7. Rights Granted by you: By entering this contest, you understand that Milan Laser or anyone acting on behalf of Milan Laser, or its respective licensees, successors, and assigns will have the right, where permitted by law, without any further notice, review or consent to print, publish, broadcast, distribute, and use, worldwide in any media now known or hereafter in perpetuity and throughout the World, your entry, including, without limitation, the entry and winner's name, portrait, picture, voice, likeness, image or statements about the Sweepstakes, and biographical information as news, publicity or information and for trade, advertising, public relations and promotional purposes without any further compensation.</p>
        <p className="milan-fp">8. Terms: Milan Laser reserves the right, in its sole discretion, to cancel, terminate, modify or suspend the Sweepstakes should (in its sole discretion) a virus, bugs, non-authorized human intervention, fraud, or other causes beyond its control corrupt or affect the administration, security, fairness or proper conduct of the Sweepstakes. In such case, Milan Laser may select the recipients from all eligible entries received before and/or after (if appropriate) the action taken by Milan Laser. Milan Laser reserves the right at its sole discretion to disqualify any individual who tampers or attempts to tamper with the entry process or the operation of the Sweepstakes or website or violates these Terms & Conditions.</p>
        <p className="milan-fp">9. Milan Laser has the right, in its sole discretion, to maintain the integrity of the Sweepstakes, to void entries for any reason, including, but not limited to, multiple entries from the same client with multiple email addresses or aliases; or the use of other technical means for entering. Any attempt by an entrant to deliberately damage or undermine the legitimate operation of the Sweepstakes may be a violation of criminal and civil laws and should such an attempt be made, Milan Laser reserves the right to seek damages from any such person to the fullest extent permitted by law.</p>
        <p className="milan-fp">10. By entering Sweepstakes, you agree to receive email newsletters or text message notifications periodically from Milan Laser. You can opt out of receiving this communication at any time by clicking the unsubscribe link in the newsletter.</p>
        <p className="milan-fp">11. Limitation of Liability: By entering, you agree to release and hold harmless Milan Laser and its subsidiaries, affiliates, advertising and promotion agencies, partners, representatives, agents, successors, assigns, employees, officers, and directors from any liability, illness, injury, death, loss, litigation, claim or damage that may occur, directly or indirectly, whether caused by negligence or not, from (i) such entrant's participation in the Sweepstakes and/or his/her acceptance, possession, use, or misuse of any prize or any portion thereof, (ii) technical failures of any kind, including but not limited to the malfunctioning of any computer, cable, network, hardware or software; (iii) the unavailability or inaccessibility of any transmissions or telephone or Internet service; (iv) unauthorized human intervention in any part of the entry process or the Promotion; (v) electronic or human error which may occur in the administration of the Promotion or the processing of entries.</p>
        <p className="milan-fp">12. Disputes: THIS SWEEPSTAKES IS GOVERNED BY THE LAWS OF the United States, Nebraska, Iowa, Missouri, Colorado, Wisconsin, South Dakota, Minnesota, Pennsylvania, Maine, Kentucky, Tennessee, Indiana, North Dakota, Michigan, Kansas, Ohio, Texas, and New York WITHOUT RESPECT TO CONFLICT OF LAW DOCTRINES. As a condition of participating in this Sweepstakes, participant agrees that any and all disputes that cannot be resolved between the parties, and causes of action arising out of or connected with this Sweepstakes shall be resolved individually, without resorting to any form of class action, exclusively before a court located in Nebraska having jurisdiction. Further, in any such dispute, under no circumstances will participants be permitted to obtain awards for, and hereby waives all rights to claim punitive, incidental, or consequential damages, including reasonable attorneys' fees, other than participant's actual out-of-pocket expenses (i.e. costs associated with entering this Sweepstakes), and participant further waives all rights to have damages multiplied or increased.</p>
        <p className="milan-fp">13. Privacy Policy: Information submitted with an entry is subject to the Privacy Policy stated on the Milan Laser website. Read the Privacy Policy here: http://www.MilanLaser.com/privacy.html. </p>
        <p className="milan-fp">14. Winners List: For the names of the winners, please contact us at info@milanlaser.com, and in the body of the email type 'Please email me the winners’ list for the Milan Laser Sweepstakes.’</p>
        <p className="milan-fp">15. Sponsor: The Sponsor of the Sweepstakes is Milan Laser, PO Box 34457, Omaha, NE 68134.</p>
        <p className="milan-fp">16. This promotion is in no way sponsored, endorsed, administered by, or associated with Facebook, Twitter, YouTube, Pinterest, LinkedIn, or Google. You understand that you are providing your information to the owner of this Sweepstakes and not to Facebook, Twitter, YouTube, Pinterest, LinkedIn, or Google.</p>
      </div>
    </section>
  );
}

export default Sweepstakes;
