import React from "react";
import { GATSBY_DOMAIN } from "../../utils";
import { ClientSaleForm } from "../forms";


export function EnterHero({ siteData }) {
  return (
    <section className="hero pt-0 pb-0">
      <div className="hypecontain">
        <iframe className="ca-entry-hype responsive-iframe" src={`${GATSBY_DOMAIN}/client-sale-entry/Enter-v5.html`} title="Customer Appreciation 2023"></iframe>
        <ClientSaleForm siteData={siteData} />
      </div>
    </section>
  );
}

export default EnterHero;
