import React from 'react'
import { Link } from "gatsby";
import GRAPHIC from "../../../images/specials/hero/June24-Ready-To-Bare-It-All.webp";
import "./SpecialsHero.css";

function SpecialsHeroContent({setShowSignUpModal}) {
  return (
   <div id="specials-hero" className="position-relative pt-0">
   <div className="container py-lg-milan-50 position-relative">
     <div className="row">
       <div className="offset-lg-6 col-lg-6 offset-xl-6 col-xl-6 pt-4">
         {/* only hide on md */}
         <figure className="pb-4 position-relative z-index-100 text-center d-none d-lg-block">
           <img id="graphic" src={GRAPHIC} alt="Milan Laser Social" />
         </figure>
         <h1 className="smalltitle text-center">Laser Hair Removal Specials</h1>
         <p>
           There are a <i>Brazilian</i> reasons to get laser hair removal, and here's even more. This month, get 
           <strong> 50% OFF unlimited treatments for life!</strong> Our <Link to="#guarantee" className="milan-text-primary">exclusive Unlimited Package™</Link> comes with every purchase,
           so your permanent results are guaranteed.
         </p>
         {/* Desktop Only */}
         <p className="d-none d-md-block">
           <strong>Smooth Landing Bonus:</strong> Receive a <strong>FREE TREATMENT*</strong> on your underarms, bikini line, lower legs, face & neck, shoulders, or neckline when you complete a complimentary consultation this month.
         </p>
         {/* Mobile Only */}
         <p className="d-md-none">
           <strong>Smooth Landing Bonus:</strong> Receive a <strong>FREE TREATMENT*</strong> when you complete a complimentary consultation this month.
         </p> 

         <p>Give em' the old Brazzle Dazzle, and head to your <Link to="/locations/" className="milan-text-primary">local Milan Laser</Link> by <strong>June 29</strong> to get half-off unlimited laser hair removal for life!</p>
         <div className="my-4 text-center">
           <Link className="milan-btn milan-cta-btn" to="#consult">
             Book My Free Consult
           </Link>
         </div>
         <p className="mb-0 milan-fp">
           <span className="text-decoration-underline pointer" onClick={() => setShowSignUpModal(true)}>
             Stay updated with special discounts and sales by subscribing to our email list.
           </span>
           <span>
             {" "}
             We promise we'll never spam you or sell your information.{" "}
             <span className="d-md-none">
               *Free treatment excludes body ares that are currently or have been previously treated.
             </span>
             <span className="d-none d-md-inline">*Eligible body areas for free treatment include underarms, bikini line, lower legs, face & neck, shoulders, or neckline. Excludes previously treated areas.</span>
           </span>
         </p>
       </div>
     </div>
   </div>
 </div>
  )
}

export default SpecialsHeroContent