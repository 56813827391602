import React from 'react';
import {Link} from 'gatsby';

export function FooterSignup({setShowSignUpModal}) {
  return (
    <div className='px-0'>
      <h4 className='milan-text-secondary'>SIGN UP TO GET THE LATEST</h4>
      <div className='row'>
        <div className='col-12 col-lg-8'>
          <p className='mb-0 milan-fp'>By providing your email address, you agree to receive marketing communications from Milan Laser Hair Removal.</p>
          <p className='milan-fp'>
            For more about how we use your information, see our{' '}
            <Link to='/privacy/' className='milan-fp'>
              Privacy Policy
            </Link>
            .
          </p>
        </div>
        {/* <div className='col-12 col-lg-4 d-flex align-items-center'> */}
        <div className='col-12 col-lg-4 text-center'>
          <button className={'milan-btn milan-secondary-btn w-100 w-md-50 w-lg-100 success'} onClick={() => setShowSignUpModal(true)}>
            SUBSCRIBE
          </button>
        </div>
      </div>
    </div>
  );
}

export default FooterSignup;
