import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export function BestChoiceLHR() {
  return (
    <section id="best-choice-lhr">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-9 col-lg-8 col-xl-6 milan-py">
            <h2 className="subheadlg milan-text-primary pb-2">Why Professional</h2>
            <h2 className="subheadsm milan-text-primary pb-3">Laser Hair Removal is the Best Choice</h2>
            <p><span className="d-none d-sm-block">At-home hair removal devices are time-consuming and painful when treating larger areas and may need <strong>multiple sessions</strong> to treat a full area depending on battery life. It’s also <strong>difficult to reach certain areas</strong> on your own. Professional laser hair removal is your best bet for <strong>permanent results and safe, effective treatments.</strong></span><span className="d-block d-sm-none">At-home devices are time-consuming and painful when treating larger areas and need multiple sessions. It’s also difficult to reach certain areas on your own. Laser hair removal is best for permanent results and safe, effective treatments.</span><strong> All skin tones are safely treated</strong> with professional laser hair removal.</p>
            <ul className='list-style-disc'>
              <li>Professional laser hair removal offers <strong>tailored options for everyone.</strong></li>
              <li><strong>Larger treatment areas can be done much more efficiently.</strong> </li>
              <li><strong>Cooling features</strong> on the laser reduce potential discomfort.</li>
              <li><strong>Hard-to-reach areas aren’t a problem for providers.</strong></li>
              <li>Quick, easy treatments with <strong>no downtime</strong>—can usually be done over a lunch break!</li>
              <li>Milan Laser treatments are a one-time cost with <strong>no hidden fees</strong>—our <strong>Unlimited Package™ ensures no touch-up fees down the road.</strong></li>
              <li><span className="d-inline d-sm-none">Treatments are done in a judgment-free environment by medical professionals.</span><span className="d-none d-sm-inline">Professional laser hair removal treatments are done in a <strong>judgment-free environment by medical professionals.</strong></span></li>
            </ul>
          </div>
          <div className="col-12">
            <div className="d-md-none text-center">
              <StaticImage src="../../images/homedevice/BestChoice_Desktop.webp" alt="Professional Laser Hair Removal Is The Best Choice" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BestChoiceLHR