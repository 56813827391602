import React from 'react'

export function MilitaryHero() {
  return (
    <section className="hero">
      <div className="container">
        <div className="row justify-content-lg-end">
          <div className="col-lg-6">
            <div className="bg-transparent-5 p-4 rounded">
              <h1>Milan’s Military Discount</h1>
              <h2 className='subheadsm'>Milan has a special discount for our service members!</h2>
              <p>Milan Laser is proud to support our military members. We know that for those who serve, having a clean and professional appearance is essential. Milan is here to help you stay in tip-top shape, in and out of your uniform, with a <strong>military discount of 60% off any laser hair removal.</strong></p>
              <p>You’ll always be ready for inspection with smooth, hair-free skin because laser hair removal results are permanent. Ingrown hairs, messy stubble, and unsightly razor burn will be problems of the past for you.</p>
              <p className="mb-0">We’re offering an <strong>exclusive 60% off</strong> unlimited laser hair removal for military members—discount may not be combined with any other promotion—with a valid active military i.d. For more details and exclusions that may apply, book your free consultation today. It’s time to start your journey to exceptional, smooth skin.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MilitaryHero