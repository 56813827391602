import React from 'react';
import {Link} from 'gatsby';
import {MILAN_PHONE, NO_RAZOR} from '../../../utils';
import ComingSoonThumbnail from '../../../images/locations/coming-soon-cam-sm.webp';
import FrontDoorTemp from '../../../images/locations/FrontDoor_Temp.webp';

export function StoreDescription({currentStore}) {
  return (
    <div className='container store-description py-5'>
      <h2 className='text-center subheadlg mb-4 milan-text-primary'>Our {currentStore?.marketingCity} Location</h2>
      <div className='row flex-lg-row-reverse justify-content-center '>
        <div className='col-lg-5 text-center mb-3 mb-lg-0'>
          {currentStore?.storeImages?.shoppingcentersignage ? (
            <img src={currentStore?.storeImages?.shoppingcentersignage} alt={`Milan Laser ${currentStore?.marketingCity} Shopping Center Signage`} className='img-thumbnail' />
          ) : currentStore?.storeImages?.frontdoor ? (
            <img src={currentStore?.storeImages?.frontdoor} alt={`Milan Laser ${currentStore?.marketingCity} Front Door`} className='img-thumbnail' />
          ) : !currentStore?.open ? (
            <img src={ComingSoonThumbnail} className='img-thumbnail' alt='Coming soon' />
          ) : (
            <img src={FrontDoorTemp} className='img-thumbnail' alt='Default Front Door' />
          )}
        </div>
        <div className='col-lg-5'>
          <div className='anchor-blue text-center text-lg-start'>
            {currentStore?.open && currentStore?.description.line_one && currentStore?.description.line_one !== '' ? (
              <p>
                Our {currentStore?.marketingCity} location is conveniently located {currentStore?.description.line_one}
                {currentStore?.description.line_two}.
              </p>
            ) : (
              <>
                <p>We are thrilled to announce that we will soon be opening a location near you!</p>
                <p>
                  For years, we have been helping our clients get the smooth, hair-free skin that they want at a price that they can afford, and we are excited to be opening a
                  location in the {currentStore?.marketingCity} area.
                </p>
              </>
            )}
            <p>
              For more information, call&nbsp;
              <a href={`tel:${currentStore?.phone === '' ? MILAN_PHONE : currentStore?.phone}`} className='milan-text-primary'>
                {currentStore?.phone === '' ? NO_RAZOR : currentStore?.phone}
              </a>{' '}
              or&nbsp;
              <Link to='/locations/contact/#askQ' className='milan-text-primary'>
                submit your question online
              </Link>
              .
            </p>
            {currentStore?.open && currentStore?.phone !== '' && !currentStore?.awards?.length > 0 && (
              <div className='my-4'>
                <a href={`tel:${currentStore?.phone}`} className='milan-btn milan-fallback-btn'>
                  Call Our {currentStore?.marketingCity} Location
                </a>
              </div>
            )}
            {currentStore?.awards?.length > 0 && (
              <ul className='awards-wrapper d-flex flex-wrap justify-content-center justify-content-lg-start ps-0'>
                {currentStore?.awards?.map((award, x) => (
                  <li key={x} className='award-item'>
                    <a href={award.link !== '' ? award.link : null} className='w-100' target='_blank'>
                      <img src={award.url} alt={award.name} className='w-100' />
                    </a>
                  </li>
                ))}
              </ul>
            )}
            <span id='staff'></span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default StoreDescription;
