export const MILAN_SITE_START = 'https://milanlaser';
export const CORPORATE_SITE = MILAN_SITE_START + '.com';
export const GATSBY_DOMAIN = MILAN_SITE_START + 'gatsby.com';
export const DOWNLOAD_PATH = 'https://download.milanlaser.com';
export const MILAN_PHONE = '833-667-2967';
export const NO_RAZOR = '1-833-NO-RAZOR';

// export const GOOGLE_REVIEWS_URL_START = 'https://www.google.com/search?q=milan+laser+hair+removal+'
export const YELP_URL_START = 'https://www.yelp.com/biz/milan-laser-hair-removal-';
export const FACEBOOK_URL_START = 'https://www.facebook.com/MilanLaser';
// export const MILAN_CORS = 'https://cors-milanlaser.herokuapp.com/'
export const MILAN_CORS = 'https://milan-cors-2023-9de078d0fd3b.herokuapp.com/';
export const WEB_TO_LEAD_LINK = MILAN_CORS + 'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8';
export const NEWSLETTER_FORM_HANDLER = MILAN_CORS + 'https://go.milanlaser.com/l/642813/2024-05-28/k1msr';
export const COUPON_FORM_HANDLER = MILAN_CORS + 'https://go.milanlaser.com/l/642813/2024-05-28/k1mlg';
export const CUSTOM_QUOTE_FORM_HANDLER = MILAN_CORS + 'https://go.milanlaser.com/l/642813/2018-12-05/h654';
export const FAQS_FORM_HANDLER = MILAN_CORS + 'https://go.milanlaser.com/l/642813/2024-06-24/k4zg1';
export const FIND_PLACE_GOOGLE_MAPS_URL = MILAN_CORS + 'https://maps.googleapis.com/maps/api/place/findplacefromtext/json';
export const NEARBY_SEARCH_GOOGLE_MAPS_URL = MILAN_CORS + 'https://maps.googleapis.com/maps/api/place/nearbysearch/json';
export const DISTANCE_MATRIX_GOOGLE_MAPS_URL = MILAN_CORS + 'https://maps.googleapis.com/maps/api/distancematrix/json';
export const GEOCODE_GOOGLE_MAPS_URL = MILAN_CORS + 'https://maps.googleapis.com/maps/api/geocode/json';
export const STANDARD_INQUIRY_URL = MILAN_CORS + 'https://go.milanlaser.com/l/642813/2018-12-05/h64s';
export const SELF_SCHEDULE_URL = MILAN_CORS + 'https://go.milanlaser.com/l/642813/2024-02-28/jqr4t';
export const FORM_ACTION_LP_LINK = MILAN_CORS + 'https://go.milanlaser.com/l/642813/2018-11-30/gz1s';
export const FORM_ACTION_CONTACT_LINK = 'https://go.milanlaser.com/l/642813/2018-12-05/h652';
export const GOOGLE_REVIEWS_API_URL = MILAN_CORS + 'https://maps.googleapis.com/maps/api/place/details/json';
// export const KICKBOX_URL = 'https://us-central1-milanlaser-fcb24.cloudfunctions.net/api/kickbox/'

export const FIREBASE_STORAGE_PATH = `https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/`;

export const PLANS_START_AT = '45';
export const WAXING_COST_MONTH = 29;
export const WAXING_COST_YEAR = 23;
export const TREATMENTS_COUNT_MONTH = 50;
export const PULSES_PER_DAY = 50;
export const BRAZILIAN_WAX_COST_LIFETIME = 15;
export const BIKINI_WAX_COST_LIFETIME = 11;

export const BREAKPOINTS = {xxs: 0, xs: 320, sm: 576, md: 768, lg: 992, xl: 1200, xxl: 1400};

export const NETLIFY_FUNCTIONS_PATH = '/.netlify/functions/';
