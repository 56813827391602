import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { PressCustomToggle, PressMilanMediaContCard } from ".";
import { pressMilanMediaContList } from "./util/pressMilanMediaContList";

//Milan In The Media Cont.
export function PressMilanMediaCont() {
  const mediaShow = pressMilanMediaContList.slice(0, 4);
  const mediaHidden = pressMilanMediaContList.slice(4);

  return (
    <section id="press-releases" className="milan-bg-secondary py-5">
      <div className="container">
        <h2 className="subheadlg">Milan In The Media Cont.</h2>
        {/* first we put the first three videos */}
        <Accordion defaultActiveKey="1">
          <div className="row">
            {mediaShow.map((media, i) => (
              <PressMilanMediaContCard media={media} key={i} />
            ))}
            {/* after the first four, it will be collapsable */}
            <Accordion.Collapse eventKey="0">
              <div className="row justify-content-md-center">
                {mediaHidden.map((media, i) => (
                  <PressMilanMediaContCard media={media} key={i} />
                ))}
              </div>
            </Accordion.Collapse>
          </div>
          <PressCustomToggle />
        </Accordion>
      </div>
    </section>
  );
}

export default PressMilanMediaCont;
