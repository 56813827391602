import React from 'react'
import { Link } from 'gatsby'
import HeaderLogo from './HeaderLogo'
import SpecialsBanner from './SpecialsBanner'
import BurgerBtn from './BurgerBtn'

function HeaderTop({ isOpen, setIsOpen }) {
  return (
    <section id="header-top">
      <div className="container">
        <nav className="row justify-content-center align-items-center">
          <div className="col-xl-12 col-xxl-10">
            <div className="row justify-content-between align-items-center">
              <div className="col-auto">
                <HeaderLogo setIsOpen={setIsOpen} />
              </div>
              <div className="col-auto">
                <div className="row align-items-center">
                  <div className="col-auto d-none d-lg-block overflow-hidden">
                    <SpecialsBanner />
                  </div>
                  <div id="consult-col" className="col-auto">
                    <Link to='#consult' className="milan-btn consult-btn">FREE CONSULT</Link>
                  </div>
                  <div className="col-auto d-inline-block d-lg-none">
                    <BurgerBtn handleClick={() => setIsOpen(!isOpen)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </section>
  )
}

export default HeaderTop