import React from 'react'
import { Link } from 'gatsby'
import HeroTitle from '../../../images/clientsale/CA-Hero-Title-Expired.webp'
// import * as Yup from 'yup'
// import { Formik, Form } from 'formik'
// import { FormikControl, SubmitBtn, FormFailed, FormSubmitting } from '..'
// import { LocationsDropdownWrapper } from '..'
// import { doc, setDoc, Timestamp } from "firebase/firestore"
// import { createUserWithEmailAndPassword } from "firebase/auth"
// import { auth, client_sale_db } from '../../../firebase/client_sale'
// import { hardTrim } from '../../../utils'


// const validationSchema = Yup.object().shape({
//   name: Yup.string().required('Required'),
//   email: Yup.string().email('Invalid email').required('Required'),
//   phone: Yup.string().min(15, 'Invalid phone number').required('Required'),
//   location: Yup.string().required('Required'),
// })

// const Success = () => (
//   <div className="text-center pt-4">
//     <h2 className="milan-text-primary subheadlg">You're Entered!</h2>
//     <p className="milan-text-tur subheadsm">You have successfully entered to win one of three unlimited laser hair removal packages through our 2023 Customer Appreciation Drawing. Don’t stop there <Link to='/share'>share your discount</Link> with your friends and family!</p>
//   </div>
// )

const Expired = () => (
  <div className="w-100 card p-4 milan-bg-white">
    <div className="text-center">
      <p className="subheadsm"><strong>Customer Appreciation 2023</strong> is over, but we’ll see you in 2024! Thank you to everyone who entered the drawing, and remember to check your email to see if you won a free laser hair removal package.</p>
      <p className="mb-0"><Link to='/specials'>Click here</Link> to see our current laser hair removal specials and save big! </p>
    </div>
  </div>
)

// const onSubmit = async (values, helpers) => {
//   helpers.setSubmitting = true
//   const { email, phone, name, location } = values
//   const FIREBASE_COLLECTION = 'subscribers'
//   try {
//     const { user } = await createUserWithEmailAndPassword(auth, email.trim(), hardTrim(phone))
//     if (user) {
//       const subscriber = {
//         name: name.trim(),
//         email: email,
//         phone: phone,
//         location: location,
//         createdAt: Timestamp.now(),
//       }

//       try {
//         await setDoc(doc(client_sale_db, FIREBASE_COLLECTION, user.uid), subscriber)
//         helpers.setStatus('Success')
//       } catch (err) {
//         console.error('DB error: adding new subscriber failed', err)
//         helpers.setStatus('Failed')
//       }
//     }
//   } catch (error) {
//     console.log(error)
//     helpers.setStatus('Failed')
//   }
// }

export function ClientSaleForm({ siteData }) {
  return (
    <div className="w-100 h-100 z-index-100 position-relative">
      <div className="container enter-form">
        <div className="row">
          <div className="col-sm-8 col-xl-7 col-xxl-6">
            <figure className="d-none d-sm-block position-relative mt-4 mt-lg-5 py-5 mb-0">
              <img src={HeroTitle} alt="" className="w-100" />
            </figure>
            <Expired />
            {/* <Formik
              initialValues={{ name: '', email: '', phone: '', location: '' }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              validateOnChange={false}>
              {
                formik => {
                  return (
                    <Form className="w-100 card p-3 milan-bg-white">
                      <input type="hidden" name="cloudamp__data__c" />
                      {
                        formik.status === 'Success' ? <Success /> : formik.status === 'Failed' ? <FormFailed /> : formik.isSubmitting ? <FormSubmitting />
                          : <>
                            <p className="p-subhead milan-text-primary text-center mb-2"><strong>Enter to win</strong></p>
                            <div className="row">
                              <div className="col-12 col-lg-6 mb-2 text-start">
                                <FormikControl control="input" name="name" placeholder="Name" type="text" />
                              </div>
                              <div className="col-12 col-lg-6 mb-2 text-start">
                                <FormikControl control="emailInput" placeholder="Email" name="email" />
                              </div>
                              <div className="col-12 col-lg-6 mb-2 text-start">
                                <FormikControl control="phoneInput" name="phone" type="tel" />
                              </div>
                              <div className="col-12 col-lg-6 mb-2 text-start">
                                <LocationsDropdownWrapper {...{ siteData, formik }} fieldName="location" />
                              </div>
                            </div>
                            <SubmitBtn formik={formik} />
                            <p className="mb-0 mt-3 milan-fp mx-auto">I consent by electronic signature to be contacted by Milan Laser by live agent, email & automatic telephone dialer for information, offers or advertisements via email/ phone call/ text message at the number & email provided. No purchase necessary.</p>
                          </>
                      }
                    </Form>
                  )
                }
              }
            </Formik> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientSaleForm