import qs from 'qs';
import axios from 'axios';
import {NEWSLETTER_FORM_HANDLER, CORPORATE_SITE, hardTrim} from '../../../utils';
import {navigate} from 'gatsby';

export const submitSignUpForm = (values, helpers, isFooter) => {
  helpers.setSubmitting(true);
  axios({
    method: 'POST',
    url: NEWSLETTER_FORM_HANDLER,
    data: qs.stringify({
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone: `+${hardTrim(values.phoneMask)}`,
      updates: true,
      '00N1L00000F9eBV': values.location,
      oid: process.env.GATSBY_OID,
      lead_source: 'Newsletter',
      retUrl: CORPORATE_SITE,
    }),
    config: {headers: {'Content-Type': 'application/x-www-form-urlencoded'}},
  })
    .then((res) => {
      console.log(res.status, 'Subscribed to mailchimp');
      helpers.setSubmitting(false);
      helpers.setStatus('Success');
      isFooter ? navigate('?signup-footer#footer-top') : navigate('?signup-success');
      setTimeout(() => {
        helpers.resetForm();
      }, 4000);
    })
    .catch((err) => {
      console.error(err);
      helpers.setSubmitting(false);
      helpers.setStatus('Failed');
    });
};
