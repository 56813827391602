import {useState, useEffect} from 'react';
import {ref, listAll, getDownloadURL, getMetadata} from 'firebase/storage';
import {storage} from '../firebase/firebase';
import {trimAll} from '../utils';

export function useAwardsImages(metro, store) {
  const storagePath = `stores/${trimAll(metro?.state)?.toLowerCase()}/${metro?.metroPath}/${store?.pathname}/awards/`;
  const awardsImagesRef = ref(storage, storagePath);
  const [awardsImages, setAwardsImages] = useState([]); // Array

  useEffect(() => {
    // To avoid duplications
    setAwardsImages([]);
    const getAwardsImages = () => {
      listAll(awardsImagesRef)
        .then((res) => {
          if (res.items.length > 0) {
            res.items.forEach((item) => {
              let imageRef = ref(storage, `${storagePath}${item.name}`);
              let award = {};
              const promises = [];
              promises.push(getMetadata(imageRef), getDownloadURL(item));
              Promise.all(promises)
                .then((responseArr) => {
                  // responseArr will be [{metadata}, 'url'] for each award item
                  award.link = responseArr[0].customMetadata.link;
                  award.name = responseArr[0].customMetadata.name;
                  award.imgName = item.name;
                  award.url = responseArr[1];
                  setAwardsImages((prev) => [...prev, award]);
                })
                .catch((err) => {
                  console.log(err);
                });
            });
          } else {
            setAwardsImages([]);
          }
        })
        .catch((err) => {
          console.error('Error in listAll in getAwardsImages', err);
        });
    };
    if (awardsImages.length === 0) {
      getAwardsImages();
    }
  }, [awardsImagesRef._location.path]);

  return awardsImages.sort((a, b) => a.name.localeCompare(b.name));
}
export default useAwardsImages;
