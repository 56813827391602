import React, {useState, useEffect} from 'react';
import {usePosition, useNearbySearch} from '../../../hooks';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import {MILAN_SITE_START} from '../../../utils';
import {NearbyStoreCard} from '.';
import {TextError} from '../../../components';

export function FindMilanNearYou({siteData}) {
  const currentState = siteData.selectedStore.state ? siteData.milanStores.find((state) => state.name === siteData.selectedStore.state.name) : siteData.milanStores[0];
  const currentMetro = siteData.selectedStore.metro ? currentState.metros.find((metro) => metro.pathname === siteData.selectedStore.metro.pathname) : currentState.metros[0];
  const [selectedState, setSelectedState] = useState(currentState);
  const [selectedMetro, setSelectedMetro] = useState(currentMetro);
  const [zipCode, setZipCode] = useState({value: '', valid: true});
  const {latitude, longitude, errorMsg, setIsGeoEnabled} = usePosition(true); // Returns user's geoCode
  const {nearbyMilanStores, loadingNearbyStores, setLoadingNearbyStores, feedback} = useNearbySearch(latitude, longitude, siteData, setIsGeoEnabled, true, zipCode);
  const [loadMore, setLoadMore] = useState(false);

  // states dropdown
  const statesDropdownHandler = (event) => {
    const foundState = siteData.milanStores.find((state) => state.name === event.target.value);
    siteData.updateSelectedStore(foundState.metros[0].stores[0].salesforceValue);
  };
  // metros dropdown
  const metrosDropdownHandler = (event) => {
    const foundState = siteData.milanStores.find((state) => state.name === siteData.selectedStore.state.name);
    let foundMetro = foundState.metros.find((metro) => metro.name === event.target.value);
    // in case of a borrowed metro, foundMetro will be undefined, so
    if (!foundMetro) {
      if (foundState.borrowed_metros) {
        let borrowedMetro = foundState.borrowed_metros.find((borrowed) => borrowed.name === event.target.value);
        foundMetro = siteData.milanStores.find((state) => state.abbreviation === borrowedMetro.borrow_from).metros.find((metro) => metro.pathname === borrowedMetro.pathname);
      } else return;
    }
    siteData.updateSelectedStore(foundMetro.stores[0].salesforceValue);
  };

  const handleZipCode = (e) => {
    if (e.target.value.trim().length > 5 || !isFinite(e.target.value.trim())) {
      setZipCode({value: e.target.value.slice(0, 5).trim(), valid: false});
    } else {
      setZipCode({value: e.target.value.trim(), valid: true});
    }
  };

  useEffect(() => {
    if (errorMsg) setLoadingNearbyStores(false);
    if (siteData.selectedStore.store) {
      const toBeSelectedState = siteData.milanStores.find((state) => state.name === siteData.selectedStore.state.name);
      setSelectedState(toBeSelectedState);
      const toBeSelectedMetro = toBeSelectedState.metros.find((metro) => metro.pathname === siteData.selectedStore.metro.pathname);
      setSelectedMetro(toBeSelectedMetro);
    }
  }, [errorMsg, siteData.selectedStore]);

  return (
    <div id='milan-near-you' className='container-fluid'>
      {/* **************** Your Nearest Milan Location **************** */}
      <div className='row justify-content-center'>
        <div className='col-12'>
          <h2 className='subheadsm text-center milan-text-tur pb-2'>Your Nearest Milan Location{nearbyMilanStores.length > 1 ? 's' : ''}</h2>
          {loadingNearbyStores && feedback === '' ? (
            <div className='text-center mb-5' style={{color: 'var(--milan-text-primary)'}}>
              <span className='milan-text-primary'>
                Finding your nearest Milan <Spinner animation='grow' size='sm' className='ms-2' />
              </span>
            </div>
          ) : nearbyMilanStores.length !== 0 ? (
            <ul className='px-0 row gy-4 justify-content-center mt-1 pb-4'>
              {nearbyMilanStores.slice(0, 4).map((store, x) => (
                <li key={x} className='col-sm-6 col-md-5 col-lg-5 text-center'>
                  <NearbyStoreCard {...{store, zipCode}} />
                </li>
              ))}
              {nearbyMilanStores.length > 4 &&
                loadMore &&
                nearbyMilanStores.slice(4, nearbyMilanStores.length).map((store, x) => (
                  <li key={x + 4} className='col-sm-6 col-md-5 col-lg-5 text-center'>
                    <NearbyStoreCard {...{store, zipCode}} />
                  </li>
                ))}
            </ul>
          ) : null}
          {nearbyMilanStores.length > 4 && (
            <div className='text-center mb-4'>
              <span className='milan-text-primary text-decoration-underline pointer' onClick={() => setLoadMore(!loadMore)}>
                Load {loadMore ? 'less' : 'more'} locations
              </span>
            </div>
          )}
        </div>
      </div>
      {errorMsg === 'User denied Geolocation' && feedback === '' && (
        <div className='row justify-content-center pb-5'>
          <div className={`col-12  text-center mb-5 `}>
            <small className='d-inline-block mt-1 position-relative show-tooltip'>
              - <em className='help milan-text-primary text-decoration-underline'>Geolocation is disabled in your browser</em> -
            </small>
          </div>
          <div className='col-12'>
            <div className='mx-auto' style={{maxWidth: '160px'}}>
              <Form.Control id='zipcode' name='zipcode' placeholder='Search by zipcode' className='text-center' value={zipCode.value} onChange={handleZipCode} />
              {(!zipCode.valid || feedback === 'Zipcode Failed') && <TextError>Enter a valid zipcode</TextError>}
            </div>
          </div>
        </div>
      )}
      {feedback !== '' && (
        <div className='row justify-content-center pb-5'>
          <div className={`col-12 position-relative text-center mb-4`}>
            <small className='d-inline-block mt-1'>
              <em className='milan-text-primary'>No Results!</em>
            </small>
          </div>
          <div className='col-12'>
            <div className='mx-auto' style={{maxWidth: '160px'}}>
              <Form.Control id='zipcode' name='zipcode' placeholder='Zipcode' className='text-center' value={zipCode.value} onChange={handleZipCode} />
              {(!zipCode.valid || feedback === 'Zipcode Failed') && <TextError>Enter a valid zipcode</TextError>}
            </div>
          </div>
        </div>
      )}

      {/* **************** Link to the selected/found metro area **************** */}

      {/* **************** States & Metro Areas Dropdowns **************** */}
      <div className='row justify-content-center py-5 border-top'>
        <div className='col-sm-6 col-md-5 col-lg-5'>
          <h6 className='pb-1 milan-text-navy'>Select a State</h6>
          <div>
            <Form.Select aria-label='milan states' value={selectedState ? selectedState.name : 'Loading states...'} onChange={statesDropdownHandler}>
              {siteData.milanStores.length !== 0 &&
                siteData.milanStores.map((state, x) => (
                  <option key={x} value={state.name}>
                    {state.name}
                  </option>
                ))}
            </Form.Select>
          </div>
        </div>
        <div className='col-sm-6 col-md-5 col-lg-5 mt-4 mt-sm-0'>
          <h6 className='pb-1 milan-text-navy'>Select a Metro</h6>
          <div>
            <Form.Select aria-label='current metro' value={selectedMetro ? selectedMetro.name : 'Loading metros...'} onChange={metrosDropdownHandler}>
              {selectedState &&
                selectedState.metros.map((metro, x) => (
                  <option key={x} value={metro.name}>
                    {metro.name}
                  </option>
                ))}
              {selectedState &&
                selectedState?.borrowed_metros?.length > 0 &&
                selectedState?.borrowed_metros.map((borrowed, b) => (
                  <option key={b} value={borrowed.name}>
                    {borrowed.name}
                  </option>
                ))}
            </Form.Select>
          </div>
        </div>
      </div>
      {selectedMetro && (
        <div className='row py-4 justify-content-center border-top light-gray-bg'>
          <div className='col-sm-6 col-md-5 col-lg-5 text-center'>
            {/* <a href={`${MILAN_SITE_START}${selectedMetro.pathname}.com`} className='milan-btn-sm milan-primary-btn'> */}
            <a
              href={
                selectedMetro.isMacrosite ? `/locations/${selectedMetro.stateAbbrev.toLowerCase()}/${selectedMetro.metroPath}` : `${MILAN_SITE_START}${selectedMetro.pathname}.com`
              }
              className='milan-btn-sm milan-primary-btn'
            >
              Visit Our {selectedMetro.name} Website
            </a>
          </div>
        </div>
      )}
      {/* <div className="row justify-content-center py-4 light-gray-bg">
        <a href={`${CORPORATE_SITE}/locations/`} target="_blank" className="text-center milan-text-primary text-decoration-underline">See All Milan Locations</a>
      </div> */}
    </div>
  );
}

export default FindMilanNearYou;
