import { Link } from "gatsby";
import React from "react";

// const MAP_WITH_BADGE = "https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fshared%2Fmap_with_badge.png?alt=media&token=1f6f9ead-129f-49e9-9cd4-cdc426c5e21c&_gl=1*xj6h3k*_ga*MTczMTYzNjM5MC4xNjQxMTg3ODg4*_ga_CW55HF8NVT*MTY4NjY3NTY5MS40Ni4xLjE2ODY2NzY2OTguMC4wLjA."
import MAP_WITH_BADGE from '../../images/shared/map_with_badge.webp'


export function LargestLHRCompany() {
  return (
    <section id="largest-lhr" className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <h2 className="subheadlg milan-text-primary">Largest Laser Hair Removal Company in the Country</h2>
            <p>
              We are the largest laser hair removal company in the country, and since every purchase includes our exclusive{" "}
              <Link to="/specials/#guarantee" className="milan-text-primary">
                Unlimited Package™
              </Link>
              —allowing clients to be treated at any of our locations nationwide—it’s never been easier to get smooth, hair-free skin. Our no-interest
              payment plans, lifetime guarantee, expertise, and convenient locations make Milan the simplest solution to getting rid of unwanted hair
              for good.
            </p>
            <p>
              Founded in 2012 by board certified medical doctors, Milan performs over 50,000 treatments every month. Our goal is to provide state of
              the art laser treatments in a fun, relaxing environment.
            </p>
            <div className="text-center text-md-start mb-4">
              <Link to='/locations' className="milan-btn milan-cta-btn w-auto">
                Find Your Nearest Location
              </Link>
            </div>
          </div>
          <div className="d-none d-lg-block col-12 col-lg-6">
            <div className="text-center mb-4 mt-md-4 mt-lg-5">
              <img src={MAP_WITH_BADGE} className="mw-100" alt="Milan Laser Locations Map"></img>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default LargestLHRCompany;
