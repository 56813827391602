import React from 'react';
import {Link} from 'gatsby';
import {FooterSignup, FooterSocialIcons} from '.';
import {MilanLogo} from '../shared';

export function FooterBottom({siteData, setShowSignUpModal}) {
  return (
    <section id='footer-bottom' className='w-100 py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-9 col-lg-8'>
            <FooterSignup siteData={siteData} setShowSignUpModal={setShowSignUpModal} />
          </div>
          <div className='col-md-3 offset-lg-1 d-none d-md-block text-center text-lg-start mt-4 pt-2 mt-lg-0 pt-lg-0'>
            <Link to='/'>
              <MilanLogo parentClasses='d-inline-block' type='white' />
            </Link>
            <FooterSocialIcons />
          </div>
        </div>
        <div className='row mt-4'>
          <p className='milan-fp'>
            Milan Laser Hair Removal &copy; {new Date().getFullYear()}. All rights reserved. †Laser hair removal/reduction is intended to effect stable, long-term, or permanent
            reduction through selective targeting of melanin in hair follicles. Individual results may vary. Permanent results does not necessarily imply the elimination of all
            hairs in the treatment area. *APRs as low as 0-2.6%. Milan laser is not a bank or lender and does not provide loans or credit.
          </p>
        </div>
      </div>
    </section>
  );
}
export default FooterBottom;
