import React from "react";
import {Link} from "gatsby";
import GRAPHIC from "../../../images/specials/hero/June24-Ready-To-Bare-It-All.webp";

function SpecialsHeroContent() {
  return (
    // <section className="position-relative d-md-flex align-items-center">
    <div className="container fixed-height d-md-flex align-items-center">
        <div className="row">
          <div className="col-md-6 offset-md-6 offset-lg-6 col-lg-6 offset-xs-1">
            <figure className="d-none d-md-block pb-4 text-center">
              <img id="graphic" src={GRAPHIC} alt="Milan Laser Social" style={{ width: "78.65%" }}/>
            </figure>
            <p className="pt-3 pt-md-none">
              There are a <i>Brazilian</i> reasons to get laser hair removal, and here's even more. This month, get <strong>1 FREE treatment plus 50% unlimited treatments for life!</strong> Our <Link to="/specials/#guarantee" className="link-color">exclusive 
              Unlimited Package™</Link> comes with every purchase,
              so your permanent results are guaranteed. Give em' the old Brazzle dazzle, and head to your <Link to="/locations" className="link-color">local Milan Laser</Link> by <strong>June 29</strong>.
            </p>
            <div className="mt-4 text-center">
              <Link className="milan-btn milan-cta-btn w-auto" to="#consult">
                Book My Free Consult
              </Link>
            </div>
            <div className="mt-4 text-center ">
            <p className="mb-0 milan-fp pb-milan-50 pb-md-0">
              <span>
                <span className="d-md-none">
                  *Free treatment excludes body areas that are currently or have been previously treated.
                </span>
                <span className="d-none d-md-inline">*Eligible body areas for free treatment include underarms, bikini line, lower legs, face & neck, shoulders, or neckline. Excludes previously treated areas.</span>
              </span>
            </p>
            </div>
          </div>
        </div>
    </div>
    // </section>
  );
}

export default SpecialsHeroContent;

{
  /* <section className="hero position-relative d-md-flex align-items-center pt-0 pb-md-0">
<div className="d-md-none stack-hero-bg" />
<div className="container position-relative">
  <div className="row">
    <div className="col-md-6 offset-md-6 offset-lg-6 col-lg-6 offset-xs-1">
      <figure className="d-none d-md-block pb-4 position-relative z-index-100 text-center">
        <img id="graphic" src={GRAPHIC} alt="Milan Laser Social" style={{ width: "40%" }} />
        <img id="graphic" src={GRAPHIC} alt="Milan Laser Social" style={{ width: "78.65%" }}/>
      </figure>

      <p className="mobile-text" style={{paddingTop: "15px"}}>
        Here's the long and <i>shorts</i> of it: Get a leg up on smooth skin with 
        <strong> one FREE treatment</strong> plus <strong>50% OFF unlimited laser hair removal</strong> this month!
        Our <Link to="/specials/#guarantee" className="link-color">exclusive Unlimited Package™</Link> comes with every purchase,
        so you get smooth skin for life, and your permanent results are guaranteed. Shake a leg, and visit Milan Laser Hair Removal by <strong>April 30</strong>!
      </p>
      <div className="mt-4 text-center">
        <Link className="milan-btn milan-cta-btn w-auto" to="#consult">
          Book My Free Consult
        </Link>
      </div>
      <div className="mt-4 text-center ">
      <p className="mb-0 milan-fp">
        <span className="mobile-text">
          <span className="d-md-none">
            *Free treatment excludes body ares that are currently or have been previously treated.
          </span>
          <span className="d-none d-md-inline">*Eligible body areas for free treatment include underarms, bikini line, lower legs, face & neck, shoulders, or neckline. †Excludes previously treated areas.</span>
        </span>
      </p>
      </div>
    </div>
  </div>
</div>
</section> */
}
