import React, {useState, useEffect} from 'react';
import {useMedicalDirectors} from '../../../hooks';

const Employee = ({employee, marketingCity}) => {
  return (
    <div className='col-10 col-sm-6 col-md-4 col-lg-3'>
      <div className='img-thumbnail shadow-sm mb-3' style={{minHeight: '300px'}}>
        <img src={employee.url} alt={`Milan laser ${marketingCity} staff`} className='w-100' />
        <div className='text-center py-2'>
          <div>
            <strong>{employee.displayName}</strong>
          </div>
          <div>{employee.position}</div>
        </div>
      </div>
    </div>
  );
};

export function StaffPhotos({currentStore}) {
  const medicalDirectors = useMedicalDirectors();
  const [clinicMD, setClinicMD] = useState({displayName: '', url: '', position: ''});
  useEffect(() => {
    if (medicalDirectors.length > 0) {
      const MD = medicalDirectors.filter((md) =>
        md?.locations.find((location) => location?.name === currentStore?.marketingCity && location?.stateAbbrev === currentStore?.stateAbbrev)
      )[0];
      if (MD) {
        setClinicMD({
          displayName: MD.displayName,
          url: MD.url,
          position: 'Medical Director',
        });
      }
    }
  }, [medicalDirectors, currentStore]);

  return (
    //if there is no MD and no Staff, then just return empty tag
    clinicMD.url === '' && clinicMD.displayName === 'Not Assigned' && currentStore.staff.length === 0 ? (
      <></>
    ) : (
      <div className='container pb-5'>
        <h2 className='text-center subheadlg mb-4 milan-text-primary'>Our {currentStore.marketingCity} Staff</h2>
        <div className='row justify-content-center'>
          <div className='row col-xl-11 justify-content-center' style={{minHeight: '430px'}}>
            {clinicMD?.url !== '' && clinicMD?.displayName !== 'Not Assigned' && <Employee employee={clinicMD} marketingCity={currentStore.marketingCity} />}
            {currentStore.staff.map((employee, x) => {
              return <Employee key={x} employee={employee} marketingCity={currentStore.marketingCity} />;
            })}
          </div>
        </div>
      </div>
    )
  );
}

export default StaffPhotos;
