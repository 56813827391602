import React from 'react'
const benefitsPto = 'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fcareers%2Fbenefits-logo-unlimitedPTO.svg?alt=media&token=ddafedae-2eb7-4912-936b-30bcd1be869a'
const benefitsFreeLhr = 'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fcareers%2Fbenefits-logo-freeLHR.svg?alt=media&token=51e38f14-1cbc-44da-b03b-1d481ee2d550'
const benefits401k = 'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fcareers%2Fbenefits-logo-401k.svg?alt=media&token=b4efb37d-df32-475b-8087-28eea9f1ebd0'
const benefitsEmpFund = 'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fcareers%2Fbenefits-logo-assistance.svg?alt=media&token=cac7255b-3228-420d-afc5-978e0fe1f24e'
const benefitsEnv = 'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fcareers%2Fbenefits-logo-environment.svg?alt=media&token=732eeb5d-9d95-4914-b9d5-667a2c28a0a2'

export function CareersBenefits() {
  return (
    <section id="benefits" className="benefits-bg d-flex align-items-center pt-3 pt-md-0">
      <div className="container section-width text-white">
        <h2 className="text-center pb-3 pb-5-sm subheadlg">Benefits</h2>
        <p className="pb-4">
          We want you to be happy, so we’re giving you comprehensive benefits that include competitive pay, paid vacation, off on major holidays and
          401k options! Of course, we also offer health, dental, disability, and life insurance. Did we also mention free laser hair removal?
        </p>
        <div className="row text-center values-logos">
          <div className="col col-md-4 pb-4">
            <img src={benefitsPto} alt="unlimited pto logo"></img>
            <div>
            <p className="pt-2 mb-0"><strong>GENEROUS PTO</strong></p>
              <p>
                Full-time employees have flexible schedules and generous PTO <strong>PLUS</strong> 7 holidays.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4 pb-4">
            <img src={benefitsFreeLhr} alt="free lhr logo"></img>
            <div>
              <p className="pt-2 mb-0"><strong>FREE LHR</strong></p>
              <p>Employees and their spouses receive free laser hair removal treatments.</p>
            </div>
          </div>
          <div className="col-12 col-md-4 pb-4">
            <img src={benefits401k} alt="401k logo"></img>
            <div>
            <p className="pt-2 mb-0"><strong>401K</strong></p>
              <p>4% match of employee contributions. Contributions are immediately fully vested.</p>
            </div>
          </div>
          <div className="col-12 col-md-6 pt-md-3 pb-4 pb-md-0">
            <img src={benefitsEmpFund} alt="employee assistance fund logo"></img>
            <div>
            <p className="pt-2 mb-0"><strong>EMPLOYEE ASSISTANCE FUND</strong></p>
              <p>Provides grants to employees with financial hardships.</p>
            </div>
          </div>
          <div className="col-12 col-md-6 pt-md-3 pb-4 pb-md-0">
            <img src={benefitsEnv} alt="environment logo"></img>
            <div>
            <p className="pt-2 mb-0"><strong>ENVIRONMENT</strong></p>
              <p>Thrive in a collaborative environment with casual dress code and catered lunches.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CareersBenefits;
