import React from 'react'

export function HomeDeviceHero() {
  return (
    <section className="hero">
      <div className="container">
        <div className="row justify-content-md-end">
          <div className="col-md-8 col-lg-6">
            <h1 className="milan-text-primary pb-0  ">At-home <br />Hair Removal Devices</h1>
            <h1 className="smalltitle mb-4 milan-text-primary">vs. Laser Hair Removal</h1>
            <p className="d-none d-sm-block">If you’re sick of shaving pitfalls and wanting to remove unwanted hair, then you’ve heard of at-home hair removal devices. They sound great and promise a one-time cost and ease of use in the comforts of your own home. But at-home hair removal devices aren’t all they claim to be and can <strong>cost you more time and money in the long run.</strong></p>
            <p className="d-block d-sm-none">If you’re sick of shaving pitfalls then you’ve heard of at-home hair removal devices. But at-home hair removal devices cost you more time and money in the long run.</p>
            <ul className="list-style-disc">
              <li>Most at-home devices use <strong>Intense Pulsed Light (IPL)</strong>, which <strong>targets the melanin in the hair to destroy follicles.</strong> </li>
              <li>At-home handheld devices are either <strong>IPL</strong> or <strong>diode lasers.</strong></li>
              <li>IPL is <strong>not true laser technology.</strong></li>
              <li>Both technologies rely on the <strong>pigment in the hair follicle</strong> to work.</li>
            </ul>
            <p>So what are the other differences between professional laser hair removal and at-home devices like IPL and diode? <strong>Let’s dive in.</strong></p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeDeviceHero