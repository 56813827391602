import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { PressCustomToggle, PressReleaseCard } from ".";
import { pressReleasesList } from "./util/pressReleasesList";

export function PressReleases() {
  const releaseShow = pressReleasesList.slice(0, 4);
  const releaseHidden = pressReleasesList.slice(4);

  return (
    // Press Release
    <section id="press-releases" className=" milan-bg-tur py-5 hr-divider hr-divider-consult hr-divider-reversed">
      <div className="container">
        <h2 className="subheadlg">Press Releases</h2>
        {/* first we put the first three videos */}
        <Accordion defaultActiveKey="1">
          <div className="row">
            {releaseShow.map((release, i) => (
              <PressReleaseCard release={release} key={i} />
            ))}
            {/* after the first four, it will be collapsable */}
            <Accordion.Collapse eventKey="0">
              <div className="row justify-content-md-center">
                {releaseHidden.map((release, i) => (
                  <PressReleaseCard release={release} key={i} />
                ))}
              </div>
            </Accordion.Collapse>
          </div>
          <PressCustomToggle />
        </Accordion>
      </div>
    </section>
  );
}

export default PressReleases;