import React, {useContext, useState} from 'react';
import {Header, ConsultationForm, Footer, SignUpModal} from '../components';
import {GlobalSiteDataContext} from '../context';
import {hardTrim, minLayout} from '../utils';
import '../utils/styles/global.css';
import {config} from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';

config.autoAddCss = false;

export function Layout({children, location}) {
  const siteData = useContext(GlobalSiteDataContext);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  return (
    <div id={`${location.pathname === '/' ? 'home' : hardTrim(location.pathname)}-page-wrapper`} className={minLayout[hardTrim(location.pathname)] ? 'min-layout' : ''}>
      <Header siteData={siteData} />
      <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY} scriptProps={{async: true}}>
        {children}
        <ConsultationForm siteData={siteData} location={location} />
        {showSignUpModal && <SignUpModal {...{siteData, showSignUpModal, setShowSignUpModal}} />}
        <Footer siteData={siteData} setShowSignUpModal={setShowSignUpModal} />
      </GoogleReCaptchaProvider>
    </div>
  );
}

export default Layout;
