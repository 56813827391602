import React, {useCallback} from 'react';
import {Formik, Form} from 'formik';
import {FormSubmitting, FormFailed, SignUpConsentMsg, FormikControl} from '..';
import {faqsInitialValues as initialValues, faqsSchema as validationSchema, submitFaqsForm, FaqsSuccess} from '.';
import {LocationsDropdownWrapper} from '../shared';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {callVerifyHuman} from '../../../utils';

const askNewQuestionHandler = (formik) => {
  formik.setStatus('New question');
  formik.setFieldValue('description', 'Ask a new question');
};

export function FaqsForm({siteData}) {
  const {executeRecaptcha} = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    const user = await callVerifyHuman('faqsForm', executeRecaptcha);
    return user;
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  const onSubmit = async (values, helpers) => {
    helpers.setSubmitting(true);
    const user = await handleReCaptchaVerify();
    // console.log('reCAPTCHA user faqsForm: ', user);
    if (user === 'bot') {
      helpers.setErrors('bot', true);
      return;
    } else {
      submitFaqsForm(values, helpers);
    }
    helpers.setSubmitting(false);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnChange={false}>
      {(formik) => {
        return (
          <Form className='w-100 pb-4'>
            <input type='hidden' name='cloudamp__data__c' />
            {formik.isSubmitting ? (
              <FormSubmitting />
            ) : formik.status === 'Success' ? (
              <FaqsSuccess askNewQuestionHandler={askNewQuestionHandler} formik={formik} />
            ) : formik.status === 'Failed' ? (
              <FormFailed />
            ) : (
              <>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='mb-2'>
                      <FormikControl control='textarea' name='description' placeholder='Your Question' rows='7' role='input' aria-required />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='row gx-2'>
                      <div className='col-md-6'>
                        <div className='mb-2'>
                          <FormikControl control='input' name='first_name' placeholder='First Name*' type='text' role='input' aria-required />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='mb-2'>
                          <FormikControl control='input' name='last_name' placeholder='Last Name*' type='text' role='input' aria-required />
                        </div>
                      </div>
                    </div>
                    <div className='mb-2'>
                      <FormikControl control='phoneInput' name='phone' placeholder='Phone Number*' type='tel' role='input' aria-required />
                    </div>
                    <div className='mb-2'>
                      <FormikControl control='emailInput' name='email' placeholder='Your Email*' role='input' aria-required />
                    </div>
                    <div>
                      <LocationsDropdownWrapper {...{siteData, formik}} fieldName='location' />
                    </div>
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='d-flex mt-2 mt-md-0'>
                    <FormikControl control='mailchimp' name='updates' mailchimpID='mailchimp-faqs' />
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-12 text-center'>
                    <button className='milan-btn milan-red-btn w-100 send-question-btn' type='submit' disabled={!formik.isValid}>
                      SEND QUESTION{' '}
                    </button>
                  </div>
                  <div className='col-12'>
                    <small className='milan-fp lh-sm'>
                      <SignUpConsentMsg />
                    </small>
                  </div>
                </div>
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default FaqsForm;
