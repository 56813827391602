import React, { useEffect, useState } from 'react'
import { Link } from '../../utils'
import { useClickOutside } from '../../hooks'
import { MILAN_PHONE } from '../../utils'
import Form from 'react-bootstrap/Form'
import PhoneIcon from '../../images/shared/Phone-Icon-Reverse.png'
import X_ICON from '../../images/shared/X.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faSquareFacebook, faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";
import BurgerBtn from './BurgerBtn'
import SearchResults from './SearchResults'

export function MobileMenu({ menu, isOpen, closeMenu, searchHandler, searchInput, hits }) {
  const domNode = useClickOutside(() => closeMenu())
  const [activeSubMenu, setActiveSubMenu] = useState(null)
  const [activeSubSubMenu, setActiveSubSubMenu] = useState(null)

  const [slideSubMenu, setSlideSubMenu] = useState(false)
  const [slideSubSubMenu, setSlideSubSubMenu] = useState(false)
  const [slideSearchView, setSlideSearchView] = useState(false)

  const handleOnFocus = () => {
    setSlideSubSubMenu(false)
    setSlideSubMenu(false)
    setSlideSearchView(true)
  }

  const displaySubMenu = menuItem => {
    setActiveSubMenu(menuItem)
    setSlideSubMenu(true)
  }

  const displaySubSubMenu = subMenuItem => {
    setActiveSubSubMenu(subMenuItem)
    setSlideSubSubMenu(true)
  }

  const goToSubMenu = () => {
    setSlideSubSubMenu(false)
  }

  const goToMainMenu = () => {
    setSlideSubSubMenu(false)
    setSlideSubMenu(false)
    setSlideSearchView(false)
  }

  useEffect(() => {
    // When closing the menu -> Reset
    if (!isOpen) {
      setTimeout(() => {
        goToMainMenu()
      }, 800)
    }
  }, [isOpen])


  return (
    <section id="mobile-menu" className={`d-lg-none ${isOpen ? 'isOpen' : ''}`}>
      <nav ref={domNode} className={`nav-menu float-end pt-4 shadow position-relative ${isOpen ? 'isOpen' : ''}`}>
        <div className="container-fluid d-flex flex-column h-100">
          <div className="row mb-3">
            <div className="col">
              <Form.Control
                value={searchInput} onChange={searchHandler} onFocus={handleOnFocus}
                className="mobile-header-search-input border-0 milan-bg-white"
                type="search" placeholder="SEARCH" />
            </div>
            {
              (slideSubMenu || slideSubSubMenu || slideSearchView) &&
              <div className="col-auto">
                <div className="d-flex align-items-center h-100">
                  <BurgerBtn handleClick={goToMainMenu} />
                </div>
              </div>
            }
            <div className="col-auto">
              <span onClick={closeMenu} className="d-flex align-items-center px-2 pointer h-100">
                <img src={X_ICON} alt="X Icon" style={{ maxHeight: '20px' }} />
              </span>
            </div>
          </div>

          <div className="d-flex flex-fill position-relative overflow-hidden">
            <ul className="mb-0 w-100 ps-0">
              {
                menu.map((menuItem, i) => (
                  <li key={i} className="menu-item milan-text-primary">
                    <div className="menu-item-link-wrapper pointer" onClick={() => displaySubMenu(menuItem)}>
                      {/*👇 Main menu links go here */}
                      {/* Specials / The Process / Areas / Locations  */}
                      <span className="menu-item-link">{menuItem.link}</span>
                      {
                        menuItem.items && <span className="caret-icon caret-icon--right"><FontAwesomeIcon icon={faAngleRight} /></span>
                      }
                    </div>
                  </li>
                )
                )}
            </ul>

            <div id="mobile-sub-menu" className={`view-wrapper ${slideSubMenu ? 'slide-left' : ''}`}>
              {
                activeSubMenu &&
                <div id="mobile-sub-menu-wrapper">
                  <div className="milan-text-tur" onClick={goToMainMenu}>
                    <span className="menu-item-link fw-light text-uppercase d-block pointer">
                      <span className="caret-icon caret-icon--left me-2"><FontAwesomeIcon icon={faAngleLeft} /></span>
                      {activeSubMenu.link}
                    </span>
                  </div>
                  <ul className="ps-0">
                    {
                      activeSubMenu.items.map((subMenuItem, x) => (
                        <li key={x} className="menu-item milan-text-primary"> {/* Ex: <li>All Locations</li> */}
                          {
                            !subMenuItem.items ?
                              <Link to={subMenuItem.pathname} className="menu-item-link" onClick={closeMenu}>
                                {subMenuItem.link}
                              </Link>
                              :
                              <span className="menu-item-link pointer" onClick={() => displaySubSubMenu(subMenuItem)}>{subMenuItem.link}
                                <span className="caret-icon caret-icon--right"><FontAwesomeIcon icon={faAngleRight} /></span>
                              </span>
                          }
                        </li>
                      ))
                    }
                  </ul>
                </div>
              }
            </div>

            <div id="mobile-sub-sub-menu" className={`view-wrapper ${slideSubSubMenu ? 'slide-left' : ''}`}>
              {
                activeSubSubMenu &&
                <div id="mobile-sub-sub-menu-wrapper">
                  <div className="milan-text-tur mb-2" onClick={goToSubMenu}>
                    <span className="menu-item-link fw-light text-uppercase pointer">
                      <span className="caret-icon caret-icon--left me-2"><FontAwesomeIcon icon={faAngleLeft} /></span>
                      {activeSubSubMenu.link}</span>
                  </div>
                  <ul className="ps-0">
                    {
                      activeSubSubMenu.items.map((subSubMenuItem, x) => (
                        <li key={x} className="menu-item milan-text-primary"> {/* Ex: <li>Facebook</li> */}
                          <Link to={subSubMenuItem.pathname} className="menu-item-link" onClick={closeMenu}>
                            {subSubMenuItem.link === 'Facebook' ? <i className="me-2"><FontAwesomeIcon icon={faSquareFacebook} /></i> :
                              subSubMenuItem.link === 'Instagram' ? <i className="me-2"><FontAwesomeIcon icon={faInstagram} /></i> :
                                subSubMenuItem.link === 'TikTok' ? <i className="me-2"><FontAwesomeIcon icon={faTiktok} /></i> :
                                  null}
                            {subSubMenuItem.link}
                          </Link>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              }
            </div>

            <div id="mobile-search-view" className={`view-wrapper ${slideSearchView ? 'slide-left' : ''}`}>
              <div id="mobile-search-view-wrapper">
                {
                  hits.length > 0 ?
                    <div className="mt-4">
                      {
                        hits[0].loading ? <p className="searching fw-light h6 milan-text-primary">Searching</p>
                          : hits[0].message ?
                            <div>
                              <p className="milan-text-red milan-fp">{hits[0].message}</p>
                              <SearchResults type="Popular" clickHandler={closeMenu} isMobile={true} />
                            </div>
                            :
                            <SearchResults type="Suggested" hits={hits} clickHandler={closeMenu} isMobile={true} />
                      }
                    </div>
                    :
                    <div className="mt-4">
                      <SearchResults type="Popular" clickHandler={closeMenu} isMobile={true} />
                    </div>
                }
              </div>
            </div>
          </div>

          <div className="row milan-bg-primary milan-text-white text-center">
            <a href={`tel:${MILAN_PHONE}`} className="d-flex align-items-center justify-content-center w-100" style={{ height: '65px' }}>
              <div className="flex-fill">
                <i><img src={PhoneIcon} alt="Phone Icon" style={{ maxHeight: '28px' }} /></i><span className="ps-2">1-833-NO-RAZOR</span>
              </div>
            </a>
          </div>
        </div>
      </nav>
    </section>
  )
}

export default MobileMenu