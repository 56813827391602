import React from "react"
import { BAHeroLayout, BA_BODY_AREAS, splitAreaNodes } from "."
import { graphql, useStaticQuery } from 'gatsby'

export function Lip_BA_Hero() {
	const lip_ba_photos = useStaticQuery(graphql`
		query lip_ba_photos {
			allFile(
				filter: {sourceInstanceName: {eq: "globalImages"}, relativeDirectory: {eq: "process/beforeafter/lip"}}
			) {
				edges {
					node {
						name
						childImageSharp {
							gatsbyImageData(quality: 90, placeholder: BLURRED, webpOptions: {quality: 90})
						}
					}
				}
			}
		}
	`).allFile.edges
  const lip_area = BA_BODY_AREAS.find(area => area.area === 'lip')
  lip_area.ba_photos = splitAreaNodes(lip_ba_photos)

  return (
    <BAHeroLayout area={lip_area}>
		<h1 className="smalltitle text-center pb-2 milan-text-primary">Laser Hair Removal</h1>
      <h1 className="text-center pb-2 milan-text-primary">Face Before &amp; After Photos</h1>
      <p className="anchor-blue mt-4">Wondering what kind of results you can expect from facial laser hair removal treatments at Milan? Most of our clients have the smooth, hair-free face that they’ve always wanted in 7 to 10 treatments. Don’t just take our word for it though—<strong>check out these before and after photos of real Milan clients!</strong></p>
    </BAHeroLayout>
  );
}

export default Lip_BA_Hero
