import React from 'react'
import { Link } from 'gatsby'
import Carousel from "react-bootstrap/Carousel"
const BA_Legs = 'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fbodybuilding%2FB-A_Legs_250.webp?alt=media&token=b64ffcdd-d329-421e-9215-58f8fa54920c'
const BA_Mens = 'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fbodybuilding%2FB-A_MensArea_250.webp?alt=media&token=ca2c1648-25af-40de-89c7-bd5efddd023b'
const BA_Underarm = 'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fbodybuilding%2FB-A_Underarm_250.webp?alt=media&token=d3285a97-946c-4bdf-832a-da87b782139d'

export function IdealForBB() {
  return (
    <section id="ideal-for-bb" className="milan-bg-off-white">
      <div className="container milan-py">
        <h2 className="subheadlg milan-text-primary">Why Laser Hair Removal is Ideal for Bodybuilders</h2>
        <div className="row">
          <div className="col-md-8 col-lg-7 col-xxl-6">
            <p>Laser hair removal is the best hair removal option for bodybuilders.</p>
            <ul className="list-style-disc">
              <li><strong>Competition Chaos.</strong><span className="d-none d-md-inline"> The days and weeks before your next bodybuilding competition will be crazy!</span> Trying to squeeze in that last-minute wax only adds to your stress. Laser hair removal gives you permanent results, so you have more time to focus on getting in one last weightlifting session or getting the perfect spray tan.</li>
              <li><strong>Always Pose With Confidence.</strong> Hair is destroyed at the root<span className="d-none d-md-inline"> and will never grow back</span>, so there’s no need to worry about stubble or razor burn distracting from your muscle definition. You’re always ready to flex on stage! </li>
              <li><strong>Fast Treatments.</strong> Laser hair removal treatments fit perfectly into your busy bodybuilding workout routine. The average treatment takes less than 30 minutes &amp; there’s no downtime after your treatments.</li>
            </ul>
            <p>Contest prep&#8212;whether it’s for Physique, Fitness, or Bikini—is physically and mentally stressful. Make it a little easier by taking the first step toward getting the permanently smooth, hair-free skin you need to highlight all of your hard work.</p>
            <p><Link to="#consult" className="milan-text-primary">Schedule your free consultation to learn more about laser hair removal today!</Link></p>
          </div>
          <div className="col-md-4 col-lg-5 col-xxl-6">
            <Carousel className="mx-auto mt-4" style={{ maxWidth: '300px' }}>
              <Carousel.Item>
                <img className="d-block w-100 rounded" src={BA_Legs} alt="Before After Legs" />
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100 rounded" src={BA_Mens} alt="Before After Mens" />
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100 rounded" src={BA_Underarm} alt="Before After Underarm" />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  )
}

export default IdealForBB