import React from 'react';
import { DOWNLOAD_PATH } from '../../utils/constants';

export function PressHero() {
  return (
    <section className='building-bg hero py-0 milan-bg-white'>
      <div className='background spacer d-lg-none' />
      <div className='container container-custom-lg'>
        <div className='row hero-height'>
          <div className='col-lg-5 col-xxl-6 vrt-divider vrt-divider-white-bg'></div>
          <div className='col-lg-7 col-xxl-6 milan-bg-white milan-text-white py-4'>
            <div className='row pt-lg-5 custom-max-width'>
              <div className='col'>
                <h1 className='milan-text-primary'>Media &amp; Information</h1>
                <p className='text-black'>
                  As the largest laser hair removal company in the United
                  States, Milan Laser provides lifelong results in a fun,
                  inclusive, and relaxing setting. By using state-of-the-art
                  laser technology and having a client-focused approach, we aim
                  to ensure 100 percent customer satisfaction in terms of
                  results and experience.
                </p>
                <p className='text-black'>
                  For more information about Milan Laser, review our{' '}
                  <a
                    href={`${DOWNLOAD_PATH}/press/resources/2024-Milan-EPK_5.31.24.pdf`}
                    target='_blank'
                    className='milan-text-primary'
                    download
                  >
                    Electronic Press Kit
                  </a>{' '}
                  and check out our{' '}
                  <a
                    href={`${DOWNLOAD_PATH}/press/resources/MilanBrandGuide-LogoUseGuide.pdf`}
                    target='_blank'
                    className='milan-text-primary'
                    download
                  >
                    Brand Guidelines.
                  </a>{' '}
                  See more about Milan in the media below.
                </p>
              </div>
            </div>
            <div className='row gy-3 gx-lg-2 gx-xl-4 py-4'>
              <div className='col-12 col-md-auto text-center z-index-100'>
                <a
                  href={`${DOWNLOAD_PATH}/press/resources/MilanLogoPack-2022.zip`}
                  download
                  className='milan-btn milan-primary-btn'
                >
                  Logo Pack
                </a>
              </div>
              <div className='col-12 col-md-auto text-center z-index-100'>
                <a
                  href={`${DOWNLOAD_PATH}/press/resources/MilanBrandImages-2024.zip`}
                  download
                  className='milan-btn milan-primary-btn'
                >
                  Image Library
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PressHero;
