import {useState, useEffect} from 'react';
import {storage} from '../firebase/firebase';
import {ref, listAll, getDownloadURL} from 'firebase/storage';
import {trimAll} from '../utils';

export function useStoreImages(state, metro, store) {
  // const storagePath = `stores/${trimAll(metro?.state)?.toLowerCase()}/${metro?.pathname}/${store?.pathname}/store/`;
  const storagePath = `stores/${trimAll(state)?.toLowerCase()}/${metro?.pathname}/${store?.pathname}/store/`;

  const storeImagesRef = ref(storage, storagePath);
  const [storeImagesObj, setStoreImagesObj] = useState({}); // Object

  useEffect(() => {
    const getStoreImages = () => {
      // Get Store Images
      listAll(storeImagesRef)
        .then((res) => {
          if (res.items.length > 0) {
            let imagesObj = {};
            res.items.forEach((item) => {
              let storeProp = item.name.split('_')[1].split('.')[0].toLocaleLowerCase(); // returns heroxl, frontdesk, txroom ...etc
              getDownloadURL(item)
                .then((url) => {
                  imagesObj[storeProp] = url;
                  setStoreImagesObj({
                    ...imagesObj,
                    [storeProp]: url,
                  });
                })
                .catch((err) => {
                  console.error('Error in getDownloadURL in storeImagesRef', err);
                });
            });
          } else {
            setStoreImagesObj({});
          }
        })
        .catch((err) => {
          console.error('storeImagesRef error', err);
        });
    };
    getStoreImages();
  }, [store]);

  return storeImagesObj;
}
export default useStoreImages;
