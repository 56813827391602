import React from "react"
import { Link } from "gatsby"

export function Men() {
  return (
    <section id="areas-men" className="milan-bg-tur">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 milan-bg-tur milan-text-white py-milan-50 py-xl-milan-150">
            <div className="row">
              <div className="offset-xl-1 col-auto">
                <h2 className="subheadlg">Laser Hair Removal Areas for Men</h2>
              </div>
              <div className="col-auto offset-xl-1 z-index-100">
                <p>Back hair, chin stubble, and hairy shoulders can be a real problem on hot summer days, under shirts, or for careers that require a clean appearance. If you’re ready to stop battling with your body, take the next step by getting rid of that excess hair. We treat a wide variety of areas that waxing can’t touch and we’re sure you’ll be satisfied with the results. Laser hair removal is the best way to get rid of hair forever and Milan is your solution.</p>
                {/* <p className="mb-lg-0"><Link to="/areas/brazilian/">Learn more about laser hair removal for men.</Link></p> */}  
                <p className="mb-xl-0 pb-4 pb-xl-0"><Link to="/areas/brazilian/">Learn more about laser hair removal for men.</Link></p>
              </div>
            </div>
          </div>
          <div className="col-xl-7 vrt-divider vrt-divider-reversed vrt-divider-turq" />
        </div>
      </div>
      <div className="stack-hero-bg d-xl-none hr-divider hr-divider-turq-light hr-divider-reversed"></div>
    </section>
  )
}
export default Men