import React from 'react'

export function ShareHero() {
  return (
    <section className="hero milan-bg-secondary">
      <div className="container">
        <h1 className="milan-text-primary text-center">Share Your Discount!</h1>
        <div className="row">
          <div id="talkable-offer"></div>
        </div>
      </div>
    </section>
  )
}

export default ShareHero