import { DOWNLOAD_PATH } from "../../../utils/constants";

export const pressMilanMediaContList = [
  {
    title: "5 Reasons You Should Try Laser Hair Removal",
    publisher: "Portland Monthly Magazine",
    source: "https://www.pdxmonthly.com/sponsored/2024/05/5-reasons-you-should-try-laser-hair-removal",
  },
  {
    title: "Laser Hair Removal Safety in the Vally of the Sun",
    publisher: "Phoenix Magazine",
    source: "https://www.phoenixmag.com/2024/05/17/laser-hair-removal-safety-in-the-valley-of-the-sun/",
  },
  {
    title: "A Brazilian Reasons For Laser Hair Removal",
    publisher: "225 Baton Rouge Magazine",
    source: "https://www.225batonrouge.com/article/baton-rouges-best-restaurants-bars-boutiques-best-225-voting-ends-today",
  },
  {
    title: "The Best At-home Laser Hair Removal Devices (Spoiler: There aren't any.)",
    publisher: "MSP Magazine",
    source: "https://mspmag.com/health-and-fitness/best-at-home-laser-hair-removal-devices/",
  },
  {
    title: "All That Braz: Brazilian Waxing vs. Laser Hair Removal",
    publisher: "Orlando Magazine",
    source: "https://www.orlandomagazine.com/all-that-braz-brazilian-waxing-vs-laser-hair-removal/",
  },
  {
    title: "5 Things To Know About Brazilian Laser Hair Removal",
    publisher: "Hour Detroit Magazine",
    source: "https://www.hourdetroit.com/sponsor-content/5-things-to-know-about-brazilian-laser-hair-removal/",
  },
  {
    title: "Embrace the Zap: 6 Benefits of Laser Hair Removal",
    publisher: "Madison Magazine",
    source: "https://www.channel3000.com/madison-magazine/embrace-the-zap-6-benefits-of-laser-hair-removal/article_6904e362-db3a-11ee-a596-2b3c14910c4f.html",
  },
  {
    title: "Which is better: waxing or laser hair removal? St. Louis experts weigh in",
    publisher: "St.Louis Magazine",
    source: "https://www.stlmag.com/branded-content/waxing-vs-laser-hair-removal-which-is-better-experts-weigh-in/",
  },
  {
    title: "AT-HOME LASER HAIR REMOVAL VS. PROFESSIONAL—WHICH IS BETTER?",
    publisher: "Salt Lake Magazine",
    source: "https://www.saltlakemagazine.com/laser-hair-removal-salt-lake-city/",
  },
  {
    title: "Smooth in the Land: 6 Benefits of Laser Hair Removal",
    publisher: "Cleveland Magazine",
    source: "https://clevelandmagazine.com/sponsored/0324-milan-laser",
  },
  {
    title: "No Fuzz, Just Buzz: 6 Benefits of Laser Hair Removal",
    publisher: "D Magazine",
    source: "https://www.dmagazine.com/sponsored/2024/02/no-fuzz-just-buzz-6-benefits-of-laser-hair-removal/",
  },
  {
    title: "Zap Happy: 6 Benefits of Laser Hair Removal",
    publisher: "Atlanta Magazine",
    source: "https://www.atlantamagazine.com/news-culture-articles/zap-happy-6-benefits-of-laser-hair-removal/",
  },
  {
    title: "Shaving’s a Real Drag: How Joey Jay Sashayed Away Unwanted Hair with Laser Hair Removal",
    publisher: "Phoenix Magazine",
    source: "https://www.phoenixmag.com/2024/01/30/shavings-a-real-drag-how-joey-jay-sashayed-away-unwanted-hair-with-laser-hair-removal/",
  },
  {
    title: "At-home Laser Hair Removal vs. Professional—Which is Better?",
    publisher: "Houstonia Magazine",
    source: "https://www.houstoniamag.com/sponsored/2024/02/at-home-laser-hair-removal-vs-professional-which-is-better",
  },
  {
    title: "Why Winter Is The Best Time To Begin Laser Hair Removal Treatments",
    publisher: "Kansas City Magazine",
    source: "https://kansascitymag.com/sponsored-content/why-winter-is-the-best-time-to-begin-laser-hair-removal-treatments/",
  },
  {
    title: "5 Things to Know About Brazilian Laser Hair Removal",
    publisher: "Austin Monthly",
    source: "https://www.austinmonthly.com/5-things-to-know-about-brazilian-laser-hair-removal/",
  },
  {
    title: "At-home Laser Hair Removal Versus Professional -- Which is Better?",
    publisher: "Boston Magazine",
    source: "https://www.bostonmagazine.com/sponsor-content/at-home-laser-hair-removal-versus-professional-which-is-better/",
  },
  {
    title: "Why Now Is The Best Time To Begin Laser Hair Removal Treatments",
    publisher: "Cincinnati Magazine",
    source: "https://www.cincinnatimagazine.com/article/why-now-is-the-best-time-to-begin-laser-hair-removal-treatments/",
  },
  {
    title: "6 Benefits of Laser Hair Removal: Tips From Milan Laser",
    publisher: "Salt Lake Magazine",
    source: "https://www.saltlakemagazine.com/benefits-of-laser-hair-milan-laser/",
  },
  {
    title: "At-home Laser Hair Removal vs Professional - Which is Better?",
    publisher: "Chicago Magazine",
    source: "https://www.chicagomag.com/promotion/at-home-laser-hair-removal-vs-professional-which-is-better/",
  },
  {
    title: "How We Perceive (and Misperceive) Laser Hair Removal Today",
    publisher: "MpIs St Paul Magazine",
    source: "https://mspmag.com/health-and-fitness/how-we-perceive-and-misperceive-laser-hair-removal-today/",
  },
  {
    title: "At-home Laser Hair Removal vs. Professional — Which is Better?",
    publisher: "405 Magazine",
    source: "https://www.405magazine.com/at-home-laser-hair-removal-vs-professional-which-is-better/",
  },
  {
    title: "It’s Laser Season! Why Now is the Best Time to Start Laser Hair Removal",
    publisher: "Pittsburgh Magazine",
    source: "https://www.pittsburghmagazine.com/its-laser-season-why-now-is-the-best-time-to-start-laser-hair-removal/",
  },
  {
    title: "Chicago Vlogger Grace Andrews Talks All Things Laser Hair Removal",
    publisher: "Chicago Magazine",
    source: "https://www.chicagomag.com/promotion/chicago-vlogger-grace-andrews-talks-all-things-laser-hair-removal/",
  },
  {
    title: "5 Things to Know About Brazilian Laser Hair Removal",
    publisher: "Mpls St Paul Magazine",
    source: "https://mspmag.com/health-and-fitness/5-things-to-know-about-brazilian-laser-hair-removal/",
  },
  {
    title: "Laser Hair Removal For PCOS: Does It Work?",
    publisher: "Kansas City Magazine",
    source: "https://kansascitymag.com/sponsored-content/laser-hair-removal-for-pcos-does-it-work/",
  },
  {
    title: "LASER HAIR REMOVAL FOR PCOS: DOES IT WORK?",
    publisher: "303 Magazine",
    source: "https://303magazine.com/2023/09/laser-hair-removal-for-pcos-does-it-work/",
  },
  {
    title: "Debunking 5 Myths About Laser Hair Removal",
    publisher: "HOUR Detroit Magazine",
    source: "https://www.hourdetroit.com/sponsor-content/debunking-5-myths-about-laser-hair-removal/",
  },
  {
    title: "Men’s Guide to Laser Hair Removal",
    publisher: "Houstonia Magazine",
    source: "https://www.houstoniamag.com/sponsored/2023/08/mens-guide-to-laser-hair-removal/",
  },
  {
    title: "The Secret Skincare Benefits of Laser Hair Removal",
    publisher: "Pittsburgh Magazine",
    source: "https://www.pittsburghmagazine.com/the-secret-skincare-benefits-of-laser-hair-removal/",
  },
  {
    title: "Less Shaving, More Saving: Why You Should Try Laser Hair Removal",
    publisher: "405 Magazine",
    source: "https://www.405magazine.com/why-you-should-try-laser-hair-removal/",
  },
  {
    title: "Get Sensational Summer Skin with Laser Hair Removal",
    publisher: "Baltimore Magazine",
    source: "https://www.baltimoremagazine.com/special/get-sensational-summer-skin-with-laser-hair-removal/",
  },
  {
    title: "CHOOSING THE RIGHT LASER HAIR REMOVAL COMPANY & WHY IT MATTERS",
    publisher: "303 Magazine",
    source: "https://303magazine.com/2023/07/choosing-the-right-laser-hair-removal-company-why-it-matters/",
  },
  {
    title: "AT-HOME LASER HAIR REMOVAL VS. PROFESSIONAL—WHICH IS BETTER?",
    publisher: "Cincinnati Magazine",
    source: "https://www.cincinnatimagazine.com/article/at-home-laser-hair-removal-vs-professional-which-is-better/",
  },
  {
    title: "5 Things You Should Do Before Laser Hair Removal",
    publisher: "Northshore Magazine",
    source: "https://www.nshoremag.com/shop-renew/5-things-you-should-do-before-laser-hair-removal/",
  },
  {
    title: "Ingrown hairs? Laser hair removal can help!",
    publisher: "Houstonia",
    source: "https://www.houstoniamag.com/sponsored/2023/05/ingrown-hairs-laser-hair-removal-can-help",
  },
  {
    title: "Lose Unwanted Hair, Gain Confidence With Laser Hair Removal.",
    publisher: "Phoenix Mag",
    source: "https://www.phoenixmag.com/2023/05/16/lose-unwanted-hair-gain-confidence-with-laser-hair-removal/",
  },
  {
    title: "Laser Hair Removal is for Everyone.",
    publisher: "Austin Monthly",
    source: "https://www.austinmonthly.com/laser-hair-removal-is-for-everyone/",
  },
  {
    title: "Is laser hair removal worth getting? The experts break it down.",
    publisher: "CultureMap Dallas",
    source: "https://dallas.culturemap.com/news/fashion/milan-laser-hair-removal/",
  },
  {
    title: "The Time is Now",
    publisher: "Baltimore Magazine",
    source: "https://www.baltimoremagazine.com/special/the-time-is-now/",
  },
  {
    title: "Laser Hair Removal 101: Before, During & After Treatments",
    publisher: "Northshore Magazine",
    source: "https://www.nshoremag.com/shop-renew/laser-hair-removal-101-before-during-after-treatments/",
  },
  {
    title: "Less Shaving, More Saving: Why You Should Try Laser Hair Removal",
    publisher: "Austin Monthly Magazine",
    source: "https://www.austinmonthly.com/less-shaving-more-saving-why-you-should-try-laser-hair-removal/",
  },
  {
    title: "Save Time and Money with Laser Hair Removal",
    publisher: "Phoenix Magazine",
    source: "https://www.phoenixmag.com/2023/03/24/save-time-and-money-with-laser-hair-removal/",
  },
  {
    title: "How Does Laser Hair Removal Work?",
    publisher: "Cincinnati Magazine",
    source: "https://www.cincinnatimagazine.com/article/how-does-laser-hair-removal-work/",
  },
  {
    title: "At-Home Laser Hair Removal vs. Professional—Which is Better? ",
    publisher: "Philadelphia Magazine",
    source: "https://www.phillymag.com/sponsor-content/laser-hair-removal/",
  },
  {
    title: "Revive: Laser Hair Removal with Milan Laser",
    publisher: "Nashville Lifestyles",
    source: "https://nashvillelifestyles.com/living/beauty-wellness/revive-laser-hair-removal-for-men-milan-laser/",
  },
  {
    title: "Why Laser Hair Removal is a Must-have for Your Skincare Routine",
    publisher: "Boston Magazine",
    source: "https://www.bostonmagazine.com/sponsor-content/laser-hair-removal-skincare-boston/",
  },
  {
    title: "Laser Hair Removal vs. Electrolysis: What's the Difference?",
    publisher: "Chicago Mag",
    source: "https://www.chicagomag.com/promotion/laser-hair-removal-vs-electrolysis-whats-the-difference/",
  },
  {
    title: "Why I Decided to Sack Razors and Get Laser Hair Removal",
    publisher: "Men's Health",
    source: "https://www.menshealth.com/grooming/a40589964/why-i-decided-to-sack-razors-and-get-laser-hair-removal/",
  },
  {
    title: "So, You Want to Get Laser Hair Removal?",
    publisher: "Cosmopolitan",
    source: "https://www.cosmopolitan.com/style-beauty/a40591416/so-you-want-to-get-laser-hair-removal/",
  },
  {
    title: "My Journey With Self-Confidence and PCOS",
    publisher: "ELLE",
    source: "https://www.elle.com/beauty/a42536177/i-struggled-with-pcos-induced-hirsutism-heres-how-i-found-my-confidence/",
  },
  {
    title: "Debunking Myths about Milan Laser Hair Removal for Darker Skin",
    publisher: "ESSENCE",
    source: "https://www.essence.com/beauty/skin/milan-laser-debunking-myths-about-laser-hair-removal/",
  },
  {
    title: "PCOS Possibilities: Laser Hair Removal",
    publisher: "ESSENCE",
    source: "https://www.essence.com/health-and-wellness/milan-laser-pcos/",
  },
  {
    title: "Laser Hair Removal For Men",
    publisher: "ESSENCE",
    source: "https://www.essence.com/beauty/skin/milani-laser-hair-removal-for-men/",
  },
  {
    title: "Smooth as Silk",
    publisher: "Edge Magazine",
    source: "https://edgemagazine.com/smooth-as-silk/",
  },
  {
    title: "Milan Laser Hair Removal Names New President",
    publisher: "Beauty Packaging",
    source: "https://www.beautypackaging.com/contents/view_breaking-news/2022-11-23/milan-laser-hair-removal-names-new-president/",
  },
  {
    title: "Fact vs. Fiction: Top 5 Myths About Laser Hair Removal",
    publisher: "Chicago Mag",
    source: "https://www.chicagomag.com/promotion/fact-vs-fiction-top-5-myths-about-laser-hair-removal/",
  },
  {
    title: "Men's Guide to Laser Hair Removal",
    publisher: "Modern Luxury",
    source: "https://atlantanmagazine.com/mens-guide-to-laser-hair-removal",
  },
  {
    title: "Laser hair removal clinic opens second location in Ann Arbor",
    publisher: "M Live",
    source: "https://www.mlive.com/news/ann-arbor/2022/11/laser-hair-removal-clinic-opens-second-location-in-ann-arbor.html",
  },
  {
    title: "Everything You Need to Know About Laser Hair Removal in Boston",
    publisher: "Boston Magazine",
    source: "https://www.bostonmagazine.com/sponsor-content/laser-hair-removal-boston/",
  },
  {
    title: "Waxing vs. Laser Hair Removal",
    publisher: "Northshore Magazine",
    source: "https://www.nshoremag.com/shop-renew/waxing-vs-laser-hair-removal-which-is-better/",
  },
  {
    title: "Why Laser Hair Removal is a Go-to Treatment for Celebs",
    publisher: "Baltimore Magazine",
    source: "https://www.baltimoremagazine.com/special/smooth-moves-why-laser-hair-removal-is-a-go-to-treatment-for-celebs/",
  },
  {
    title: "Your Questions Answered",
    publisher: "Milwaukee Magazine",
    source: "https://www.milwaukeemag.com/your-questions-about-laser-hair-removal-answered/",
  },
  {
    title: "Still Fighting Unwanted Hair?",
    publisher: "Pittsburgh Magazine",
    source: "https://www.pittsburghmagazine.com/still-fighting-unwanted-hair-why-you-should-try-laser-hair-removal/",
  },
  {
    title: "6 Things You’re Too Embarrassed to Ask",
    publisher: "Philadelphia Magazine",
    source: "https://www.phillymag.com/sponsor-content/laser-hair-removal-questions/",
  },
  {
    title: "Laser Hair Removal vs. Waxing",
    publisher: "Out Front Magazine",
    source: "https://www.outfrontmagazine.com/laser-hair-removal-vs-waxing-how-do-they-compare/",
  },
  {
    title: "Top 5 Myths About Laser Hair Removal",
    publisher: "Phoenix Magazine",
    source: "https://www.phoenixmag.com/2021/05/06/fact-fiction-top-5-myths-about-laser-hair-removal/",
  },
  {
    title: "Laser Hair Removal is For Everyone",
    publisher: "Slug Magazine",
    source: "https://www.slugmag.com/community/shop-local/hair-is-hair-laser-hair-removal-is-for-everyone-with-milan-laser-hair-removal/",
  },
  {
    title: "How Laser Hair Removal Makes Your Life Easier",
    publisher: "D Magazine",
    source: "https://www.dmagazine.com/sponsored/2021/04/how-laser-hair-removal-makes-your-life-easier/",
  },
  {
    title: "Getting Ready for Beach Season",
    publisher: "Paper City",
    source: "https://www.papercitymag.com/fashion/beauty/milan-laser-hair-removal-get-ready-summer-beach-ditch-razor/",
  },
  {
    title: "Uninvite Your Razor this Holiday Season",
    publisher: "Philadelphia Style",
    source: "https://phillystylemag.com/gift-yourself-more-time-uninvite-your-razor-this-holiday-season",
  },
  {
    title: "Milan Laser Opens Its 100th Location",
    publisher: "American Spa",
    source: "https://www.americanspa.com/medical-spa/milan-laser-opens-its-100th-location",
  },
  {
    title: "The Lowdown on Laser Hair Removal",
    publisher: "Nashville Lifestyles",
    source: "https://nashvillelifestyles.com/living/beauty-wellness/laser-hair-removal/",
  },
  {
    title: "The Truth About Laser Hair Removal",
    publisher: "Indianapolis Monthly",
    source: "https://www.indianapolismonthly.com/promotions/truth-laser-hair-removal",
  },
  {
    title: "When Should You Start Laser Hair Removal?",
    publisher: "Indianapolis Monthly",
    source: "https://www.indianapolismonthly.com/promotions/milan-laser-hair-removal",
  },
  {
    title: "St Louis Mom Falls in Love with Laser Hair Removal",
    publisher: "Fantabulosity",
    source: "https://fantabulosity.com/laser-hair-removal-st-louis/",
  },
  {
    title: "Everything You Need To Know",
    publisher: "Absolute Living",
    source: DOWNLOAD_PATH + "/press/mediaCont/AbsoluteLiving.pdf",
  },
  {
    title: "Not Your Mother's Laser Hair Removal",
    publisher: "Minneapolis-Saint Paul",
    source: "http://mspmag.com/health-and-fitness/Not-Your-Mothers-Laser-Hair-Removal/",
  },
  {
    title: "What If You Never Had To Shave Again?",
    publisher: "Twin Cities Live",
    source: "https://www.twincitieslive.com/fashion/9504/milan-laser-hair-removal/",
  },
  {
    title: "Sick Of Shaving And Waxing? Give Yourself A Gift!",
    publisher: "Great Day St. Louis",
    source: "https://www.kmov.com/great_day/milan-laser-hair-removal/video_53360bea-45b0-5808-aa72-96dc7b60144f.html",
  },
  {
    title: "Important Questions To Ask About Laser Hair Removal",
    publisher: "Erin Fairchild",
    source: "https://www.herheartlandsoul.com/important-questions-to-ask-at-your-laser-hair-removal-consultation/",
  },
  {
    title: "Milan Laser Hair Removal Dispels Myths About Laser Hair Removal",
    publisher: "Fargo Monthly",
    source: "https://www.fargomonthly.com/laser-hair-removal-four-things-you-need-to-know/",
  },
  {
    title: "Top Benefits of Laser Hair Removal",
    publisher: "Laura Funk",
    source: "https://wegotthefunk.com/top-benefits-of-laser-hair-removal/",
  },
  {
    title: "Head To The Pool Without Looking For Your Razor!",
    publisher: "Colorado's Best",
    source: "https://kdvr.com/2019/09/19/ditch-your-razor-for-good/",
  },
  {
    title: "Laser Hair Removal: 4 Things You Need To Know",
    publisher: "St. Louis Magazine",
    source: "https://www.stlmag.com/partnercontent/laser-hair-removal-4-things-you-need-to-know/",
  },
  {
    title: "I Decided To Try Laser Hair Removal And This Is Why",
    publisher: "Laura Funk",
    source: "https://wegotthefunk.com/laser-hair-removal-and-this-is-why/",
  },
  {
    title: "5 Reasons To Start Milan Laser Hair Removal",
    publisher: "Chic Talk",
    source: "https://chictalkch.com/2017/09/milan-laser-hair-removal-treatments.html",
  },
  {
    title: "Update On My Laser Hair Treatments",
    publisher: "Chic Talk",
    source: "https://chictalkch.com/2017/12/update-laser-hair-removal-treatments.html",
  },
  {
    title: "Best Laser Hair Removal Treatments In Denver",
    publisher: "Chic Talk",
    source: "https://chictalkch.com/2017/05/best-laser-hair-removal-denver.html",
  }
];
