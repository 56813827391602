import React from 'react'
import { Link } from 'gatsby'
import { MilanLogo } from "../shared"

function HeaderLogo({ setIsOpen }) {
  return (
    <Link to="/" className="d-inline-block nav-logo" onClick={() => setIsOpen(false)}>
      <MilanLogo parentClasses="d-block d-lg-none" type="blue" />
      <MilanLogo parentClasses="d-none d-lg-block d-xl-none" type="white" />
      <MilanLogo parentClasses="d-none d-xl-block me-lg-3" imgClasses="" type="white_wide" />
    </Link>
  )
}

export default HeaderLogo