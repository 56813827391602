import React from 'react'
import { Formik, Form } from 'formik'
import { FormikControl, FormSubmitting, FormFailed, LocationsDropdownWrapper } from '..'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import * as Yup from 'yup'
import axios from 'axios'
import qs from 'qs'
import { WEB_TO_LEAD_LINK, hardTrim } from '../../../utils'

const initialValues = {
  first_name: '',
  phone: '',
  email: '',
  location: '',
}

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.string().min(15, 'Invalid phone number').required('Required'),
  location: Yup.string().required('Required'),
})

export function ReferralForm({ siteData }) {

  const onSubmit = (formValues, props) => {
    axios({
      method: 'POST',
      url: WEB_TO_LEAD_LINK,
      data: qs.stringify({
        'first_name': formValues.first_name,
        'last_name': '',
        'email': formValues.email,
        'phone': `+${hardTrim(formValues.phone)}`,
        '00N1L00000F9eBV': formValues.location,
        'oid': process.env.GATSBY_OID,
        'lead_source': 'Website',
        'retUrl': '',
        'Campaign_ID': '7011L000001K8tL'
      }),
      config: { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
    }).then(res =>
      console.log(res.status, 'lead created'),
      props.setStatus('Success')
    ).catch((err) => {
      console.error(err)
      props.setSubmitting(false)
      props.setStatus('Failed')
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(formValues, props) => onSubmit(formValues, props)}
      validateOnChange={false}>
      {
        formik => {
          return (
            <Form id="referral-form" className="referral-form w-100 pb-4" >
              <input type="hidden" name="cloudamp__data__c" />
              {formik.isSubmitting ? <FormSubmitting /> : formik.status === "Success" ?
                <div className="text-center mt-5">
                  <h3>Grab Your Phone, <br /> We’re Calling You!</h3>
                  <p className="my-4 mx-auto" style={{ maxWidth: '800px' }}>We are so excited to speak with you and to answer any questions that you have about laser hair removal. During regular business hours you can expect to hear from us in about 15 minutes.</p>
                </div>
                : formik.status === 'Failed' ? <FormFailed />
                  : <>
                    <div className="form-inputs-wrapper pt-4">
                      <div className="row gy-3 justify-content-center mx-auto">
                        <div className="col-md-5">
                          <FormikControl control="input" name="first_name" label="Your Name" placeholder="Mila N. Laser" type="text" />
                        </div>
                        <div className="col-md-5">
                          <FormikControl control="phoneInput" name="phone" label="Phone Number" type="tel" />
                        </div>
                      </div>
                      <div className="row gy-3 justify-content-center mx-auto pt-3">
                        <div className="col-md-5">
                          <FormikControl control="emailInput" name="email" label="Your Email" placeholder="youremail@mailbox.com" />
                        </div>
                        <div className="col-md-5">
                          <label htmlFor="location">Location <sup><FontAwesomeIcon icon={faAsterisk} /></sup></label>
                          <LocationsDropdownWrapper {...{ siteData, formik }} fieldName="location" />
                        </div>
                      </div>
                    </div>
                    <div id="actions-btns" className="row justify-content-center my-3 col-lg-10 m-auto">
                      <div className="col-sm-6 col-lg-4 text-center">
                        <button
                          className="milan-btn milan-red-btn w-100" type="submit"
                          disabled={!formik.isValid}>CLAIM DISCOUNT</button>
                      </div>
                      <p className="mb-0 mt-3 milan-fp lh-base mx-auto">I consent by electronic signature to be contacted by Milan Laser by live agent, email & automatic telephone dialer for information, offers or advertisements via email/ phone call/ text message at the number & email provided. No purchase necessary.</p>
                    </div>
                  </>
              }
            </Form>
          )
        }
      }
    </Formik>
  )
}

export default ReferralForm