import React from 'react';
import {useThumbnails} from '../../../hooks';
import {StoreCard} from '.';

export function OurStateStores({currentState, milanStores}) {
  const thumbnails = useThumbnails(currentState);
  // For borrowed metros
  let borrowedMetros = [];
  if (currentState.borrowed_metros) {
    currentState.borrowed_metros.forEach((elem) => {
      let foundMetro = milanStores.find((state) => state.abbreviation === elem.borrow_from).metros.find((metro) => metro.pathname === elem.pathname);
      borrowedMetros.push(foundMetro);
      console.log('foundMetro:', foundMetro);
      console.log('borrowedMetros:', borrowedMetros);
    });
  }
  let borrowedThumbnails = useThumbnails({metros: borrowedMetros});

  return (
    <section id='our-state-stores' className='pb-5 light-gray-bg'>
      <div className='container'>
        <div className='row justify-content-center'>
          {currentState.metros.map((metro) => (
            <React.Fragment key={metro.pathname}>
              {metro.stores.map((store) => (
                <StoreCard
                  key={store.pathname}
                  store={store}
                  thumbnails={thumbnails}
                  // link={`${metro.origin}/locations/${metro.stores.length === 1 ? '' : metro.metroPath + '/'}${store.pathname}/`}
                  //if this store is part of isMacrosite, then link to corporate site
                  link={
                    metro.isMacrosite
                      ? `/locations/${currentState.abbreviation.toLowerCase()}/${metro.metroPath}/${store.pathname}/`
                      : `${metro.origin}/locations/${metro.stores.length === 1 ? '' : metro.metroPath + '/'}${store.pathname}/`
                  }
                />
              ))}
            </React.Fragment>
          ))}
          {
            // In case of borrowed metros
            borrowedMetros.map((borrowed) => (
              <React.Fragment key={borrowed.pathname}>
                {borrowed.stores
                  .filter((store) => store.stateAbbrev === currentState.abbreviation)
                  .map((store) => (
                    <StoreCard
                      key={store.pathname}
                      store={store}
                      thumbnails={borrowedThumbnails}
                      link={`${borrowed.origin}/locations/${borrowed.stores.length === 1 ? '' : borrowed.metroPath + '/'}${store.pathname}/`}
                    />
                  ))}
              </React.Fragment>
            ))
          }
        </div>
      </div>
    </section>
  );
}

export default OurStateStores;
