export const getMilanGeoCodes = (milanStores) => {
  if (milanStores.length > 0) {
    return milanStores
      .map((state) =>
        state.metros.map((metro) =>
          metro.stores.map((store) => ({
            name: store.salesforceValue,
            lat: store.geo.lat,
            long: store.geo.long,
            metro: {
              name: metro.name,
              pathname: metro.pathname,
              metroPath: metro.metroPath,
              singleStore: metro.stores.length === 1 ? true : false,
              isMacroSite: metro.isMacrosite,
            },
            state: {
              name: state.name,
              abbrev: state.abbreviation,
              isMacroSite: state.isMacrosite,
            },
          }))
        )
      )
      .flat(2);
  }
};
