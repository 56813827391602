import {useState, useEffect} from 'react';
import {collection, getDocs} from 'firebase/firestore';
import {db} from '../firebase/firebase';
import {getStore} from '../utils';
// import { STORES_LOCAL } from '../utils'

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== 'undefined';

export function useMilanStores() {
  const [milanStores, setMilanStores] = useState([]);
  const collectionRef = collection(db, 'stores-test');

  const localStorageStore = isBrowser ? window.localStorage.getItem('selectedStore') : null;
  const [selectedStore, setSelectedStore] = useState({});

  useEffect(() => {
    const getMilanStores = async () => {
      try {
        const collectionData = await getDocs(collectionRef);
        const fetchedData = collectionData.docs.map((doc) => ({id: doc.id, ...doc.data()}));
        setMilanStores(fetchedData);
        setSelectedStore(localStorageStore && fetchedData.length > 0 ? getStore(localStorageStore, fetchedData) : {});
      } catch (error) {
        console.log(error);
      }
    };
    getMilanStores();
  }, []); // Danger: DO NOT add milanStores as a dependency
  return {milanStores, selectedStore, setSelectedStore};
}
export default useMilanStores;
