import React from "react";
import Card from "react-bootstrap/Card";

export function PressReleaseCard(release) {
    return (
        <div className="col-md-3 d-flex justify-content-center item-height h-100">
        <Card>
          <Card.Body className="tile-box d-flex flex-column align-items-between">
            <h6 className="text-uppercase milan-text-navy">{release.release.title}</h6>
            <Card.Link href={release.release.source} target="_blank" className="mt-auto d-block milan-text-navy">
              Read more
            </Card.Link>
          </Card.Body>
        </Card>
      </div>
    )
}

export default PressReleaseCard