import React, {useState} from 'react';
// import { FaStar, FaDirections, FaStoreAlt, FaPhone, FaCalendarTimes } from 'react-icons/fa'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStar, faDiamondTurnRight, faShop, faPhoneFlip, faCalendarXmark} from '@fortawesome/free-solid-svg-icons';

import {MILAN_SITE_START, MILAN_PHONE} from '../../../utils';

export function NearbyStoreCard({store, zipCode}) {
  const isMacrosite = store.metro.isMacroSite ? true : false;

  const [moreInfo, setMoreInfo] = useState(false);
  return (
    <div className={`card h-100 shadow-sm rounded-0 text-start`}>
      <div className='card-body'>
        <h5 className='card-title text-capitalize mb-1'>
          <a
            href={
              isMacrosite
                ? `/locations/${store.state.abbrev.toLowerCase()}/${store.metro.metroPath}/${store.pathname}`
                : store.metro.singleStore
                ? `${MILAN_SITE_START}${store.metro.pathname}.com/locations/${store.pathname}`
                : `${MILAN_SITE_START}${store.metro.pathname}.com/locations/${store.metro.metroPath}/${store.pathname}/`
            }
            className='milan-text-primary'
            //if it's macrosite, it will open in the same tab if it's not macrosite, it will open in a new tab
            target={isMacrosite ? '_self' : '_blank'}
            // target='_blank'
            rel='noreferrer'
          >
            {store.marketingCity}
          </a>
          {store.rating && store.reviewCount && (
            <span className='float-end milan-fp milan-text-navy'>
              <FontAwesomeIcon icon={faStar} className='milan-text-tur' />{' '}
              <span>
                {store.rating}/5 ({store.reviewCount})
              </span>
            </span>
          )}
        </h5>

        <p className='card-text text-muted mb-2'>
          <small className='d-block lh-sm'>{store.address}</small>
          <small>
            {store.addressCity === '' ? store.marketingCity : store.addressCity}, {store.stateAbbrev} {store.zipCode}
          </small>
        </p>
        <div className='card-text mb-0 text-center text-sm-start'>
          <a href={`tel:${store.phone === '' ? MILAN_PHONE : store.phone}`} className='btn mb-3 border-0 rounded-0 p-0'>
            <small className='d-inline-block d-sm-none milan-primary-btn px-3 py-2 milan-btn-sm'>
              <FontAwesomeIcon icon={faPhoneFlip} className='me-2' /> Call Clinic
            </small>
            <small className='milan-text-navy d-none d-sm-block'>
              <FontAwesomeIcon icon={faPhoneFlip} className='me-1 milan-text-tur' /> <span className='text-muted'>{store.phone === '' ? MILAN_PHONE : store.phone}</span>
            </small>
          </a>
        </div>

        <ul id='nearby-stores-list' className='row gy-3 flex-wrap mb-3'>
          {!store.open && (
            <li className='col-4 text-center'>
              <div>
                {/* <FaCalendarTimes className="mb-1 milan-text-red" /> */}
                <FontAwesomeIcon icon={faCalendarXmark} className='milan-text-red' />
              </div>
              <div className='text-muted milan-fp'>Coming Soon</div>
            </li>
          )}
          <li className='col-4 text-center'>
            <a
              href={
                isMacrosite
                  ? `/locations/${store.state.abbrev.toLowerCase()}/${store.metro.metroPath}/${store.pathname}`
                  : store.metro.singleStore
                  ? `${MILAN_SITE_START}${store.metro.pathname}.com/locations/${store.pathname}`
                  : `${MILAN_SITE_START}${store.metro.pathname}.com/locations/${store.metro.metroPath}/${store.pathname}/`
              }
              // target='_blank'
              target={isMacrosite ? '_self' : '_blank'}
              rel='noreferrer'
            >
              <div>
                {/* <FaStoreAlt className="mb-1 milan-text-tur" /> */}
                <FontAwesomeIcon icon={faShop} className='milan-text-tur' />
              </div>
              <div className='text-muted milan-fp'>Clinic Page</div>
            </a>
          </li>
          {store.open && store.maps.google !== '' && (
            <li className='col-4 text-center'>
              <a href={store.maps.google} target='_blank' rel='noreferrer'>
                <div>
                  {/* <FaDirections className="mb-1 milan-text-tur" /> */}
                  <FontAwesomeIcon icon={faDiamondTurnRight} className='milan-text-tur' />
                </div>
                <div className='text-muted milan-fp'>Google Maps</div>
              </a>
            </li>
          )}
          {store.open && store.maps.apple !== '' && (
            <li className='col-4 text-center'>
              <a href={store.maps.apple} target='_blank' rel='noreferrer'>
                <div>
                  {/* <FaDirections className="mb-1 milan-text-tur" /> */}
                  <FontAwesomeIcon icon={faDiamondTurnRight} className='milan-text-tur' />
                </div>
                <div className='text-muted milan-fp'>Apple Maps</div>
              </a>
            </li>
          )}
        </ul>

        <div className='card-text'>
          <span type='button' className='milan-text-primary milan-fp text-decoration-underline' onClick={() => setMoreInfo(!moreInfo)}>
            {moreInfo ? 'Less info' : 'More info'}
          </span>
        </div>

        {moreInfo && (
          <div className='ps-1'>
            {store.description.line_one && (
              <div className='card-text milan-fp text-muted'>
                <span>
                  Located {store.description.line_one} {store.description.line_two}.
                </span>
              </div>
            )}
          </div>
        )}
      </div>
      {store && store.distance && (
        <div className='card-footer py-1 fst-italic'>
          <div>
            {store.distance && (
              <small>
                Approx. {store.distance} mi {zipCode.value !== '' && `from ${zipCode.value}`}
              </small>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default NearbyStoreCard;
