import {trimAll} from '.';
// Accepts the salesforce value as a param
// Returns the store object of the passed salesforce value (The store NOT the metro)
export const getStore = (salesforceValue, milanStores) => {
  if (salesforceValue === '' || milanStores.length === 0) return {};
  const filteredState = milanStores.find((state) => {
    // This returns the state of the requested store
    return state.metros.some(findLocation);
  });
  const filteredMetro = filteredState.metros.find(findLocation);

  function findLocation(metro) {
    return metro.stores.some((store) => store.salesforceValue.toLowerCase() === salesforceValue.toLowerCase());
  }
  let filteredStore = filteredMetro.stores.find((store) => store.salesforceValue.toLowerCase() === salesforceValue.toLowerCase());
  return {
    state: {
      name: filteredState.name,
      abbreviation: filteredState.abbreviation,
      pathname: trimAll(filteredState.name.toLowerCase()),
      isMacrosite: filteredState.isMacrosite,
    },
    metro: {
      name: filteredMetro.name,
      pathname: filteredMetro.pathname,
      metroPath: filteredMetro.metroPath,
      isMacrosite: filteredMetro.isMacrosite,
    },
    store: {...filteredStore, isMacrosite: filteredMetro.isMacrosite},
  };
};

export default getStore;
