import React from "react";
import {Carousel} from "react-bootstrap";
import SocialHeroContent from "./SocialHeroContent";

export function SocialHero() {
  return (
    <section id="social-hero" className="hero position-relative py-0 overflow-hidden">
      <div className="d-md-none stack-hero-bg"/>
      <div className="d-md-none">
        <SocialHeroContent className="d-md-none"/>
      </div>
      <div className="d-none d-md-block">
        <Carousel wrap fade interval={6500} touch={false} controls={false} indicators={false} keyboard={false}>
          <Carousel.Item className="first-bg">
            <SocialHeroContent/>
          </Carousel.Item>
          <Carousel.Item className="second-bg">
            <SocialHeroContent/>
          </Carousel.Item>
        </Carousel>
        </div>
    </section>
  );
}

export default SocialHero;