import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export function AtHomeIPL() {
  return (
    <section id="at-home-ipl" className="milan-py">
      <div className="container">
        <div className="row gx-xl-5">
          <div className="col-md-5 col-xl-6">
            <h2 className="subheadlg milan-text-primary pb-2">How Do <br />At-home IPL</h2>
            <h2 className="subheadsm milan-text-primary pb-3">Hair Removal Devices Work?</h2>
            <p className="d-none d-sm-block">Often confused with laser hair removal, IPL (intense pulsed light) technology is used in many at-home hair removal devices, instead of concentrated light and true laser technology. Hair removal <strong>results are patchy, inconsistent, and not guaranteed.</strong> Results take a long time to achieve with IPL, and once you stop using IPL, your hair will likely grow back.</p>
            <p className="d-block d-sm-none">IPL technology is used in many at-home hair removal devices. Instead of concentrated light and true laser technology, IPL releases a <strong>scattered, broad-spectrum light that’s unfocused and inaccurate.</strong> Results are patchy and take a long time to achieve with IPL, and once you stop using IPL devices, <strong>your hair will likely grow back.</strong></p>
            <ul className="list-style-disc">
              <li>Popular devices are less powerful, so <strong>more usage is required</strong><span className="d-inline d-sm-none">.</span><span className="d-none d-sm-inline">—it takes months to see any results.</span></li>
              <li>Not always regulated by the FDA, so <strong>safety is not guaranteed.</strong></li>
              <li>Many devices <strong>cannot be used for facial hair removal.</strong></li>
              <li><span className="d-inline d-sm-none">IPL devices are unsafe for darker skin tones</span><span className="d-inline d-sm-none">.</span>IPL devices are <strong>unsafe for darker skin tones</strong>—many devices block usage if skin tone is too dark.</li>
              <li>Darker skin tones <strong>carry a high burn risk</strong><span className="d-inline d-sm-none">.</span>—IPL can’t differentiate between skin and hair.</li>
            </ul>
          </div>
          <div className="col-md-7 col-xl-6">
            <StaticImage src="../../images/homedevice/How_IPL_Works_Mobile.webp" className="d-lg-none" alt="at-home-ipl" />
            <StaticImage src="../../images/homedevice/How_IPL_Works_Table.webp" className="d-none d-lg-block" alt="at-home-ipl" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default AtHomeIPL