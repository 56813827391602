import {getStore} from '../../utils';

export const updateBorrowedStores = (currentMetro, milanStores) => {
  const updatedBorrowedStore = [];
  currentMetro.borrowed_stores.forEach((store) => {
    const borrowedStore = getStore(store.details.salesforceValue, milanStores);
    store.state = borrowedStore.state;
    store.metro = borrowedStore.metro;
    store.details = borrowedStore.store;
    updatedBorrowedStore.push(store);
  });
  return updatedBorrowedStore;
};
