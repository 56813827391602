import React from 'react'
import { ContactForm } from '../forms'

export function ShareAskQuestion({ siteData }) {
  return (
    <section id="share-ask-q" className="milan-py">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-lg-6 shadow-sm bg-transparent-7">
            <div className="text-center">
              <h2 className="mt-3 py-3 subheadsm">Feel Free to Ask a Question</h2>
            </div>
            <ContactForm siteData={siteData} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ShareAskQuestion