export const CORPORATE_MENU = [
  {
    link: "Specials",
    pathname: "/specials/",
    items: [
      {
        link: "This Month's Special",
        pathname: "/specials/"
      },
      {
        link: "Payment Plans",
        pathname: "/specials/#financing"
      }
    ]
  },

  {
    link: "The Process",
    pathname: "/process/",
    items: [
      {
        link: "How it works",
        pathname: "/process/"
      },
      {
        link: "Before/After Photos",
        pathname: "/process/beforeafter/",
        items: [
          {
            link: "Face & Lip",
            pathname: "/process/beforeafter/beforeafter_lip/"
          },
          {
            link: "Underarms",
            pathname: "/process/beforeafter/beforeafter_underarm/"
          },
          {
            link: "Bikini",
            pathname: "/process/beforeafter/beforeafter_bikini/"
          },
          {
            link: "Legs",
            pathname: "/process/beforeafter/beforeafter_legs/"
          },
          {
            link: "Men's Area",
            pathname: "/process/beforeafter/beforeafter_men/"
          }
        ]
      },
      {
        link: "Candidate Quiz",
        pathname: "/process/quiz/"
      },
      {
        link: "FAQs",
        pathname: "/process/faqs/"
      },
      {
        link: "Types of Hair Removal",
        pathname: "/process/compare/"
      }
    ]
  },

  {
    link: "Body Areas",
    pathname: "/areas/",
    items: [
      {
        link: "Lip & Chin",
        pathname: "/areas/lipnchin/"
      },
      {
        link: "Legs",
        pathname: "/areas/legs/"
      },
      {
        link: "Underarm",
        pathname: "/areas/underarm/"
      },
      {
        link: "Bikini Area",
        pathname: "/areas/bikini/"
      },
      {
        link: "Brazilian Bikini",
        pathname: "/areas/brazilian/"
      },
      {
        link: "For Men",
        pathname: "/areas/for-men/"
      },
      {
        link: "For Brown Skin",
        pathname: "/areas/darkskin/"
      },
      {
        link: "All Body Areas",
        pathname: "/areas/"
      }
    ]
  },

  {
    link: "Locations",
    pathname: "/locations/",
    items: [
      {
        link: "All Locations",
        pathname: "/locations/"
      },
      {
        link: "Contact Us",
        pathname: "/locations/contact/"
      },
      {
        link: "About Us",
        pathname: "/locations/about-us/"
      },
      {
        link: "Reviews/Testimonials",
        pathname: "/locations/reviews/"
      },
      {
        link: "Social Links",
        pathname: "/locations/contact/#social",
        items: [
          {
            link: "Facebook",
            pathname: "https://www.facebook.com/MilanLaser/"
          },
          {
            link: "Instagram",
            pathname: "https://www.instagram.com/milanlaser/"
          },
          {
            link: "TikTok",
            pathname: "https://www.tiktok.com/@milanlaserhairremoval"
          }
        ]
      },
      {
        link: "Careers",
        pathname: `/careers/`
      }
    ]
  }
]