import React from "react"
import { BAHeroLayout, BA_BODY_AREAS, splitAreaNodes } from "."
import { graphql, useStaticQuery } from 'gatsby'

export function Underarm_BA_Hero() {
	const underarm_ba_photos = useStaticQuery(graphql`
		query underarm_ba_photos {
			allFile(
				filter: {sourceInstanceName: {eq: "globalImages"}, relativeDirectory: {eq: "process/beforeafter/underarm"}}
			) {
				edges {
					node {
						name
						childImageSharp {
							gatsbyImageData(quality: 90, placeholder: BLURRED, webpOptions: {quality: 90})
						}
					}
				}
			}
		}
	`).allFile.edges
  const underarm_area = BA_BODY_AREAS.find(area => area.area === 'underarm')
  underarm_area.ba_photos = splitAreaNodes(underarm_ba_photos)

  return (
    <BAHeroLayout area={underarm_area}>
			<h1 className="smalltitle text-center pb-2 milan-text-primary">Laser Hair Removal</h1>
      <h1 className="text-center pb-2 milan-text-primary">Underarm Before &amp; After Photos</h1>
      <p className="anchor-blue mt-4">Wondering what kind of results you can expect from underarm laser hair removal treatments at Milan? Most of our clients have the smooth, hair-free underarms that they’ve always wanted in 7 to 10 treatments. Don’t just take our word for it though—<strong>check out these before and after photos of real Milan clients!</strong></p>
    </BAHeroLayout>
  );
}

export default Underarm_BA_Hero