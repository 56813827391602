export const pressVideos = [
  {
    source: '/press/videos/newdaycleveland.mp4#t=0.5',
    title: 'Milan Laser Hair Removal',
    publisher: 'New Day Cleveland',
    link: 'https://spaces.hightail.com/receive/YjIOMbSliO/fi-df5f87da-9dc5-426f-a433-e12b18d12594/fv-42d2791e-aa62-4edc-88f1-c5132c2b4b6c/Milan%20Laser%20Hair%20Removal%20on%20NDC.mp4',
  },
  {
    source: '/press/videos/wsmv.mp4',
    title: 'Milan Laser Hair Removal',
    publisher: 'WSMV',
    link: 'https://www.wsmv.com/video/2023/03/02/milan-laser-hair-removal-sponsored-by-milan-laser-hair-removal/',
  },
  {
    source: '/press/videos/houston2.mp4',
    title: 'Milan Laser Hair Removal can get rid of unwanted hair for good',
    publisher: 'Great Day Houston',
    link: 'https://www.khou.com/article/entertainment/television/programs/great-day-houston/milan-laser-hair-removal-can-get-rid-of-unwanted-hair-for-good/285-907f5611-59e4-430d-9270-b11bcd6b9c21',
  },
  {
    source: '/press/videos/WCCBCharlotteCarolinaInsight.mp4',
    title: 'Carolina Insight: Milan Laser Hair Removal',
    publisher: 'WCCB Charlotte',
    link: 'https://www.wccbcharlotte.com/2023/04/14/carolina-insight-milan-laser-hair-removal/',
  },
  // {
  //   source: "",
  //   title: "Milan Laser Hair Removal makes hair removal inclusive and affordable",
  //   publisher: "WISH-TV",
  //   link: "https://www.wishtv.com/lifestylelive/milan-laser-hair-removal-makes-hair-removal-inclusive-and-affordable/",
  // },
  {
    source: '/press/videos/BSST-BACT-0035-compressed.mp4#t=1',
    title: 'The Benefits of Laser Hair Removal',
    publisher: 'The Balancing Act',
    link: 'https://thebalancingact.com/the-benefits-of-laser-hair-removal/',
  },
  {
    source: '/press/videos/My_Carolina_Milan_Laser_Hair_Removal_CBS_17.mp4',
    title: 'Milan Laser Hair Removal Raleigh',
    publisher: 'My Carolina',
    link: 'https://www.cbs17.com/my-carolina/sponsored-content-milan-laser-hair-removal/',
  },
  {
    source: '/press/videos/Inside_a_Laser_Hair_Removal_Clinic.mp4',
    title: 'Inside a Laser Hair Removal Clinic',
    publisher: 'The Morning Blend - Milwaukee',
    link: 'https://www.tmj4.com/shows/the-morning-blend/inside-a-laser-hair-removal-clinic',
  },
  {
    source: '/press/videos/What_to_expect_at_Milan_Laser_Hair_Removal.mp4',
    title: 'What to Expect at Milan Laser Hair Removal',
    publisher: 'WNWO - Toledo',
    link: 'https://nbc24.com/whats-going-on/what-to-expect-at-milan-laser-hair-removal',
  },
  {
    source: '/press/videos/Nelson-Mandela.mp4#t=1.78',
    title: 'Nelson Mandela Elementary School is getting a new technology lab',
    publisher: 'KETV',
    link: 'https://www.ketv.com/article/nelson-mandela-elementary-school-new-technology-lab/40182311',
  },
  {
    source: '/press/videos/hendersonville.mp4',
    title: 'Milan Laser Hair Removal Hendersonville',
    publisher: 'WBKO13',
    link: 'https://www.youtube.com/watch?v=HpeBix5RiG4&ab_channel=CreativeServices',
  },
  {
    source: '/press/videos/houston.mp4#t=0.1',
    title: 'Milan Laser Hair Removal Houston',
    publisher: 'Great Day Houston',
    link: 'https://www.khou.com/video/entertainment/television/programs/great-day-houston/milan-laser-hair-removal/285-a496b950-ae69-4a34-9e2e-a3e1c294a370',
  },
  {
    source: '/press/videos/HolidayGiftGuideMilanLaser.mp4',
    title: 'Milan Laser Hair Removal Nashville',
    publisher: 'Talk of the Town',
    link: 'https://www.newschannel5.com/talk-of-the-town/holiday-gift-guide-milan-laser-hair-removal',
  },
  {
    source: '/press/videos/stl.mp4',
    title: 'Milan Laser Hair Removal St. Louis',
    publisher: 'Great Day St. Louis',
    link: 'https://www.youtube.com/watch?v=kS50rBa2Abo&ab_channel=KMOVSt.Louis',
  },
  {
    source: '/press/videos/twincitieslive.mp4',
    title: 'Milan Laser Hair Removal Minneapolis',
    publisher: 'Twin Cities Live',
    link: 'https://www.twincitieslive.com/fashion/9504/milan-laser-hair-removal/',
  },
  {
    source: '/press/videos/Keep_It_In_The_O.mp4#t=0.9',
    title: 'Milan Laser Hair Removal O’Fallon, MO',
    publisher: 'Keep it in the "O"',
    link: 'https://www.youtube.com/watch?v=HFpV9AMD710&ab_channel=OFallonTV',
  },
  {
    source: '/press/videos/Milan-Laser-Hair-Removal.mp4',
    title: 'Milan Laser Hair Removal Denver, CO',
    publisher: "Colorado's Best",
    link: 'https://kdvr.com/2019/09/19/ditch-your-razor-for-good/',
  },
];
