import React, {useCallback} from 'react';
import {Formik, Form} from 'formik';
import {FormikControl, signUpInitialValues as initialValues, signUpSchema as validationSchema, submitSignUpForm, FormSubmitting, SignUpSuccess, FormFailed, SubmitBtn} from '..';
import {LocationsDropdownWrapper} from '../shared';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAsterisk} from '@fortawesome/free-solid-svg-icons';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {callVerifyHuman} from '../../../utils';

export function SignUpForm({siteData}) {
  const {executeRecaptcha} = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    const user = await callVerifyHuman('modalSignup', executeRecaptcha);
    return user;
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  const onSubmit = async (values, helpers) => {
    helpers.setSubmitting(true);
    const user = await handleReCaptchaVerify();
    console.log('reCAPTCHA user modal sign up: ', user);
    if (user === 'bot') {
      helpers.setErrors('bot', true);
      return;
    } else {
      submitSignUpForm(values, helpers);
    }
    helpers.setSubmitting(false);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnChange={false}>
      {(formik) => {
        return (
          <Form className='w-100'>
            <input type='hidden' name='cloudamp__data__c' />
            {formik.isSubmitting ? (
              <FormSubmitting />
            ) : formik.status === 'Success' ? (
              <SignUpSuccess />
            ) : formik.status === 'Failed' ? (
              <FormFailed />
            ) : (
              <>
                <div className='row gy-2'>
                  <div>
                    <div className='row mb-3 text-start gx-3'>
                      <div className='col-12 col-sm-6 mb-3 mb-sm-0'>
                        {/* // this generates the warning, The attribute aria-required is not supported by the role input  */}

                        <FormikControl control='input' name='firstName' placeholder='First Name*' type='text' required role='input' />
                      </div>
                      <div className='col-12 col-sm-6'>
                        <FormikControl control='input' name='lastName' placeholder='Last Name*' type='text' required role='input' />
                      </div>
                    </div>
                    <div className='mb-3 text-start'>
                      <FormikControl control='emailInput' name='email' placeholder='Your Email*' required role='input' />
                    </div>
                    <div className='text-start'>
                      <FormikControl control='phoneInput' name='phoneMask' placeholder='Phone Number*' type='tel' required role='input' />
                    </div>
                  </div>
                  <div id='locations-dropdown-wrapper'>
                    <label htmlFor='location'>
                      Location{' '}
                      <sup>
                        <FontAwesomeIcon icon={faAsterisk} />
                      </sup>
                    </label>
                    <LocationsDropdownWrapper {...{siteData, formik}} fieldName='location' />
                  </div>
                </div>
                <div className='row mt-1'>
                  <div className='col-12 mb-3'>
                    <p className='text-center mt-2 mb-0 lh-1'>
                      <small>
                        I consent by electronic signature to be contacted by Milan Laser by live agent, email & automatic telephone dialer for information, offers or advertisements
                        via email/ phone call/ text message at the number & email provided. No purchase necessary.
                      </small>
                    </p>
                  </div>
                  <div className='col-12 text-center'>
                    <SubmitBtn formik={formik} />
                  </div>
                </div>
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default SignUpForm;
