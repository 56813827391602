import React from 'react';
import {MILAN_SITE_START} from '../../../utils';
import {Link} from 'gatsby';

export function ShowAddressAndLink({selectedStore}) {
  return (
    <>
      <div>
        <strong>Selected location address:</strong>&nbsp;
        <br className='d-lg-none' />
        <span className='d-block d-md-inline'>
          {selectedStore.store.address}, <br className='d-sm-none' />
          {selectedStore.store.addressCity === '' ? selectedStore.store.marketingCity : selectedStore.store.addressCity},&nbsp;
          {selectedStore.store.stateAbbrev}&nbsp;{selectedStore.store.zipCode}
        </span>
      </div>
      <div className='my-2'>
        {selectedStore.metro.isMacrosite ? (
          <Link
            to={`/locations/${selectedStore.state.abbreviation.toLowerCase()}/${selectedStore.metro.pathname}/${selectedStore.store.pathname}`}
            className='milan-text-navy p-link'
            rel='noreferrer'
          >
            Learn more about Milan Laser {selectedStore.metro.name} ({selectedStore.store.marketingCity})
          </Link>
        ) : (
          <a href={`${MILAN_SITE_START}${selectedStore.metro.pathname}.com/`} target='_blank' rel='noreferrer' className='milan-text-navy p-link'>
            Learn more about Milan Laser {selectedStore.metro.name}
          </a>
        )}
      </div>
    </>
  );
}

export default ShowAddressAndLink;
