import React from "react";
import {Carousel} from "react-bootstrap";
import SpecialsHeroContent from "./SpecialsHeroContent";
import "./SpecialsHero.css";

export const HOME_PAGE_COPY = {
  default: "Fuzz around & find out! | Get 50% OFF + 1 FREE treatment!",
  grandOpening: "Grand Opening Sale: | 50% OFF unlimited laser hair removal + 1 FREE treatment.",
};

export function SpecialsHero({setShowSignUpModal}) {
  return (
    <section id="specials-hero" className="hero position-relative py-0">
      <div className="d-lg-none stacked-hero-bg"/>
      <div className="d-lg-none">
        <SpecialsHeroContent className="d-lg-none" setShowSignUpModal={setShowSignUpModal}/>
      </div>
      <div className="d-none d-lg-block">
        <Carousel wrap fade interval={6500} touch={false} controls={false} indicators={false} keyboard={false}>
          <Carousel.Item className="first-bg">
            <SpecialsHeroContent setShowSignUpModal={setShowSignUpModal}/>
          </Carousel.Item>
          <Carousel.Item className="second-bg">
            <SpecialsHeroContent setShowSignUpModal={setShowSignUpModal}/>
          </Carousel.Item>
        </Carousel>
      </div>
    </section>
  );
}
export default SpecialsHero;