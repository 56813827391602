import React from 'react'

export function InClinicLHR() {
  return (
    <section id="in-clinic-lhr" className="milan-py">
      <div className="container">
        <div className="row justify-content-md-end">
          <div className="col-md-8 col-lg-6">
            <h2 className="subheadlg milan-text-primary pb-2">Why In-clinic Laser Hair Removal is More Effective</h2>
            <h2 className="subheadsm milan-text-primary pb-3">Than At-home Devices</h2>
            <p><span className="d-none d-sm-block">Professional laser hair removal uses <strong>focused laser light to target the hair follicle.</strong>
              The light is absorbed by the pigment to heat and destroy the follicle so it can’t grow back. <strong>Results from laser hair removal treatments are permanent</strong> when performed correctly, and unlike home devices, <strong>laser hair removal works on all skin tones.</strong></span><span className="d-block d-sm-none">Laser hair removal uses focused laser light. The light is absorbed by the pigment to heat and destroy it. Results are permanent, and unlike home devices, laser hair removal works on all skin tones. Within seven to 10 treatments, most clients are <strong>95%+ hair-free</strong>, as opposed to maintenance treatments every few weeks with home devices. </span> Within seven to 10 treatments, most clients are 95%+ hair-free, as opposed to maintenance treatments every few weeks with home devices.</p>
            <ul className="d-none d-md-block list-style-disc">
              <li className="pb-2">With professional laser hair removal, <strong>there’s no one-size-fits-all process.</strong></li>
              <li className="pb-2">Milan Laser’s highly trained team of <strong>experts creates customized treatment plans</strong> for each client.</li>
              <li className="pb-2">Professional laser hair removal can be <strong>done on nearly any body area.</strong></li>
              <li className="pb-2">Milan Laser’s Candela GentleMax Pro includes <strong>two lasers to treat all skin tones</strong>—the Nd: YAG for darker skin and the Alexandrite for lighter skin tones.</li>
              <li className="pb-2"><strong>Milan is overseen by medical professionals,</strong> and laser hair removal is all we do.</li>
              <li className="pb-2">Laser hair removal is <strong>extremely safe.</strong></li>
            </ul>
            <ul className="d-block d-md-none list-style-disc">
              <li className="pb-2">No one-size-fits-all process.</li>
              <li className="pb-2">Milan Laser’s team creates customized treatment plans.</li>
              <li className="pb-2">Professional laser hair removal can be <strong>done on nearly any body area.</strong></li>
              <li className="pb-2">Milan Laser’s Candela GentleMax Pro includes <strong>two lasers to treat all skin tones.</strong></li>
              <li className="pb-2"><strong>Milan is overseen by medical professionals.</strong></li>
              <li className="pb-2">Laser hair removal is <strong>extremely safe.</strong></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default InClinicLHR